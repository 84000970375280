<template>
  <a-drawer
    :title="false"
    placement="bottom"
    :closable="false"
    :visible="visible"
    class="share-article-drawer"
  >
    <div class="share-article-container">
      <div class="share-article-container__header">
        <p>Share Article</p>
        <i class="icon icon-add_plus" @click="closeShareArticleDrawer()"></i>
      </div>
      <div class="share-article-container__body">
        <article-share-modal
          :class="dynamicClass"
          :title="title"
          :image="image"
          :source="source"
          :url="url"
          :date="date"
          :id="id"
        ></article-share-modal>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import ArticleShareModal from "./ArticleShareModal.vue";

export default {
  components: {
    ArticleShareModal,
  },
  props: {
    visible: Boolean,
    title: String,
    authorName: String,
    image: String,
    source: String,
    url: String,
    id: String,
    date: String,
    dynamicClass: String,
  },
  methods: {
    closeShareArticleDrawer() {
      this.$emit("close-share-article-drawer");
    },
  },
};
</script>

<style lang="scss">
.share-article-drawer {
  .ant-drawer-content-wrapper {
    height: auto !important;
    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        overflow: hidden;
        .ant-drawer-body {
          height: 100%;
          padding: 3.6rem 0;
          .share-article-container {
            height: 100%;
            &__header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 4.7rem;
              padding: 0 3.2rem;
              p {
                font-size: 2.8rem;
                font-family: $font-primary-bold;
                line-height: normal;
                margin-bottom: 0;
                color: $color-black;
                opacity: 1;
              }
              .icon {
                transform: rotate(45deg);
                display: block;
                cursor: pointer;
                font-size: 2.5rem;
                opacity: 1;
                color: $color-black;
              }
            }
            &__body {
              .share-article-modal {
                padding: 0;
                &__header {
                  display: none;
                }
                &__body {
                  .ant-tabs {
                    height: 100%;
                    .ant-tabs-bar {
                      border: none;
                      margin-bottom: 3.1rem;
                      .ant-tabs-nav-container {
                        padding: 0 3.2rem;
                        padding-bottom: 2px;
                        .ant-tabs-tab-arrow-show {
                          display: none;
                        }
                        .ant-tabs-nav-wrap {
                          border: none;
                          .ant-tabs-nav-scroll {
                            border: none;
                            .ant-tabs-nav {
                              border: none;
                              padding-bottom: 0;
                              overflow-x: auto;
                              &::-webkit-scrollbar {
                                display: none;
                              }
                              .ant-tabs-tab {
                                padding: 1.1rem 2rem;
                                border-radius: 100px;
                                font-size: 1.9rem;
                                border: 1px solid $color-dark-grey-5;
                                font-family: $font-primary;
                                color: #434343;
                                margin-right: 1.2rem;
                                opacity: 1;
                              }
                              .ant-tabs-tab-active {
                                font-family: $font-primary-regular;
                                background-color: $color-primary;
                                color: $color-white;
                                opacity: 1;
                                border-color: $color-primary;
                                &::after {
                                  content: none;
                                }
                              }
                              .ant-tabs-ink-bar {
                                display: none;
                              }
                            }
                            .ant-tabs-ink-bar {
                              display: none !important;
                            }
                          }
                        }
                      }
                    }
                    .ant-tabs-content {
                      .ant-tabs-tabpane {
                        overflow-y: auto;
                        max-height: calc(72vh - 10rem);
                        overflow-x: hidden;
                        -webkit-overflow-scrolling: touch;
                        overscroll-behavior: auto;
                        padding: 0 3.2rem;
                        .share-card {
                          &__image {
                            height: 30.6rem;
                            width: 100%;
                            margin-bottom: 3rem;
                            border-start-end-radius: 1.8rem !important;
                            border-start-start-radius: 1.8rem !important;
                            img {
                              object-fit: cover;
                              border-start-end-radius: 1.8rem !important;
                              border-start-start-radius: 1.8rem !important;
                            }
                          }
                          &__source {
                            margin-bottom: 0.5rem;
                            h3 {
                              font-size: 2.8rem;
                            }
                          }
                          &__author {
                            p {
                              font-size: 2.1rem;
                              font-family: $font-primary-regular;
                              opacity: 1;
                              color: $color-black;
                            }
                            h4 {
                              font-size: 2.1rem;
                              font-family: $font-primary-bold;
                            }
                          }
                          &__title {
                            padding: 1.8rem 0;
                            border-bottom: none;
                            h2 {
                              font-size: 2.1rem;
                              line-height: 3.8rem;
                            }
                          }
                          &__btn {
                            height: 7rem;
                            font-size: 2.8rem;
                          }
                        }
                        .share-with-connections {
                          &__title {
                            font-family: $font-primary-bold;
                            font-size: 2.1rem;
                            line-height: 2rem;
                          }

                          &__category {
                            p {
                              font-family: $font-primary;
                              color: $color-black;
                              width: 100%;
                              font-size: 2.1rem;
                              margin: 1.8rem 0 1rem;
                              opacity: 1;
                            }
                            .tagify {
                              margin-bottom: 0.8rem;
                              &__input {
                                font-size: 2.1rem;
                                line-height: 3.5rem;
                                padding: 1.4rem 1.8rem;
                                &::before {
                                  margin-top: 0.7rem;
                                  color: $color-black;
                                  opacity: 0.5;
                                  max-width: 87%;
                                  white-space: nowrap;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                }
                              }
                              &__tag {
                                display: flex;
                                align-items: center;
                                margin: 0.7rem 0 0.5rem 1rem;
                                background-color: #e5e5e5;
                                border-radius: 0.5rem;
                                &:hover,
                                &:focus {
                                  box-shadow: none;
                                  background-color: #cfd7ef;
                                }
                                .tagify__tag__user {
                                  .tagify__tag__avatar-wrap {
                                    width: 2.6rem;
                                    height: 2.6rem;
                                  }
                                  .tagify__tag-text {
                                    font-size: 2.2rem;
                                    line-height: 3.5rem;
                                  }
                                  &::before {
                                    background-color: transparent;
                                  }
                                }
                              }
                            }
                          }

                          textarea {
                            padding: 1.7rem 2rem;
                            font-size: 2.1rem;
                          }

                          &__btn {
                            height: 7rem;
                            font-size: 2.1rem;
                            margin-bottom: 0.5rem;
                          }

                          .error {
                            color: rgb(255, 0, 0);
                            margin: 0;
                            font-size: 1.8rem;
                          }
                        }
                      }
                      .ant-tabs-tabpane::-webkit-scrollbar-track {
                        background-color: #ffffff3f;
                      }
                      .ant-tabs-tabpane::-webkit-scrollbar {
                        width: 4px;
                        background-color: rgb(224, 224, 224);
                        border-radius: 3rem;
                      }
                      .ant-tabs-tabpane::-webkit-scrollbar-thumb {
                        background-color: $color-primary;
                        border-radius: 3rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
