<template>
  <a-modal :footer="null" @cancel="closeModal" class="add-new-category-modal">
    <div class="add-new-category-modal">
      <div class="add-new-category-modal__header">
        <h1
          v-if="action == 'edit'"
          class="add-new-category-modal__header--title"
        >
          Edit Group
        </h1>
        <h1 v-else class="add-new-category-modal__header--title">
          Create Group
        </h1>
      </div>
      <div class="add-new-category-modal__body">
        <div class="add-new-category-modal__body--add-category">
          <a-input
            v-model:value="groupTitle"
            placeholder="Enter group name here…"
          />
          <p v-if="error" class="error">{{ error }}</p>
        </div>
        <a-button
          v-if="action == 'edit'"
          :loading="loading"
          @click="editAudienceGroup"
          >Update</a-button
        >
        <a-button v-else :loading="loading" @click="addNewAudienceGroup"
          >Create</a-button
        >
      </div>
    </div>
  </a-modal>
</template>
<script>
export default {
  props: {
    action: {
      type: String,
    },
  },
  emits: ["close-modal"],
  data() {
    return {
      groupTitle: "",
      group: {},
      error: "",
      loading: false,
    };
  },
  mounted() {
    this.group = {};
  },
  methods: {
    selectGroup(group) {
      this.group = group;
      this.groupTitle = group.group_name;
    },
    async addNewAudienceGroup() {
      if (this.groupTitle == "") {
        this.error = "This field is required";
        return;
      }
      this.loading = true;
      try {
        await this.$store
          .dispatch("profile/addNewAudienceGroup", this.groupTitle)
          .then(() => {
            this.closeModal();
            const message = {
              visibility: true,
              message: "Group Created Successfully",
              type: "success",
            };
            this.emitter.emit("SuccesMsgOnNetwork", message);
          });
      } catch (err) {
        this.closeModal();
        const message = {
          visibility: true,
          message: "Something went wrong",
          type: "failure",
        };
        this.emitter.emit("SuccesMsgOnNetwork", message);
      }
      this.loading = false;
      this.closeModal();
    },
    async editAudienceGroup() {
      if (this.groupTitle == "") {
        this.error = "This field is required";
        return;
      }
      let requestObj = {
        id: this.group.id,
        group_name: this.groupTitle,
      };
      this.loading = true;
      try {
        await this.$store
          .dispatch("profile/updateGroup", requestObj)
          .then(() => {
            this.closeModal();
            const message = {
              visibility: true,
              message: "Group has been updated successfully",
              type: "success",
            };
            this.emitter.emit("SuccesMsgOnNetwork", message);
          });
      } catch (err) {
        this.closeModal();
        const message = {
          visibility: true,
          message: "Something went wrong",
          type: "failure",
        };
        this.emitter.emit("SuccesMsgOnNetwork", message);
      }
      this.loading = false;
    },
    closeModal() {
      this.group = {};
      this.groupTitle = "";
      this.loading = false;
      this.error = "";
      this.$emit("close-modal");
    },
  },
};
</script>
<style lang="scss">
.add-new-category-modal {
  width: 50rem !important;
  .ant-modal-content {
    padding: 0 !important;
    .ant-modal-body {
      padding: 0 !important;
      .add-new-category-modal {
        padding: 2.5rem;
        border-radius: 1rem;
        background-color: #f6f8ff;
        &__header {
          margin-bottom: 2.5rem;
          &--title {
            font-size: 2.4rem;
            font-family: $font-primary-bold;
            line-height: normal;
            margin-bottom: 0;
          }
        }
        &__body {
          &--add-category {
            .ant-input {
              height: 4rem;
              font-size: 1.6rem;
              font-family: $font-secondary;
              padding: 0 1.5rem;
              border: 1px solid #00000033;
              border-radius: 0.5rem !important;
              box-shadow: none;
              background-color: $color-white;
            }
          }
          .ant-btn {
            border: none;
            font-size: 1.6rem;
            font-family: $font-secondary-bold;
            cursor: pointer;
            background-color: $color-primary;
            color: $color-white;
            transition: all 0.2s;
            height: 4rem;
            width: 10rem;
            margin-left: auto;
            display: block;
            margin-top: 3rem;
            &:hover,
            &:active,
            &:focus {
              background-color: $color-primary;
              color: $color-white;
              border: none;
            }
          }
          .error {
            font-size: 1.4rem;
            font-family: $font-primary;
            color: red;
            margin-top: 0.5rem;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
