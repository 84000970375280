<template>
  <a-skeleton
    active
    :avatar="null"
    :title="{ row: 1, width: '100%' }"
    :paragraph="null"
    class="photo-resume-skeleton-loader"
  />
</template>

<style lang="scss">
.photo-resume-skeleton-loader {
  width: 100%;
  height: 18.7rem;
  margin-right: 4rem;
  .ant-skeleton-content {
    .ant-skeleton-title {
      height: 100%;
      width: 100%;
      margin: 0;
    }
    .ant-skeleton-paragraph {
      margin: 2rem 0 0;
      li {
        margin: 1rem 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
