<template>
  <a-skeleton
    active
    :title="{ width: '5rem' }"
    :paragraph="null"
    class="groups-skeleton-loader"
  />
</template>

<style lang="scss">
.groups-skeleton-loader {
  margin: 0 !important;
  display: flex;
  align-items: center;
  .ant-skeleton-content {
    .ant-skeleton-title {
        height: 3rem;
        margin: 0 1rem 1.5rem 0;
    }
  }
}
</style>
