<template>
    <a-skeleton active :avatar="null" :title="{row: 1, width: '20%'}" :paragraph="{ rows: 3, width: ['80%', '100%', '100%'] }"
        class="experience-skeleton-loader" />
</template>

<style lang="scss">
.experience-skeleton-loader {
    padding: 0 2.6rem 3rem;
    .ant-skeleton-content {
        .ant-skeleton-title {
            height: 3rem;
            margin: 0;
        }
        .ant-skeleton-paragraph {
            margin: 2rem 0 0;
            li {
                margin: 1rem 0;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
</style>
  