<template>
  <div class="connections-search-bar">
    <a-dropdown :trigger="['click']" placement="bottomRight">
      <form
        @submit.prevent
        class="connections-search-bar__header"
        @click.stop="openSearchBar"
      >
        <i class="icon icon-search" @click.stop=""></i>
        <input
          @keydown="navigateResults"
          class="search-input"
          type="text"
          v-model="searchQuery"
          placeholder="Search with name or email"
        />
      </form>
      <template #overlay>
        <a-menu>
          <a-menu-item key="0">
            <div
              @click.stop
              v-show="openDropDown"
              class="connections-search-bar__body"
            >
              <a-row :gutter="[24]">
                <a-col :xs="24" :sm="24" :md="24" :lg="24">
                  <div class="connections-search-bar__body--data">
                    <div v-if="searchQuery">
                      <h2 class="title">Search results</h2>
                      <div v-if="options.length > 0" class="all-users">
                        <div
                          :class="
                            `all-users__user ${key === highlightedResult &&
                              'highlighted-result-with-key'} ${-1 ===
                              highlightedResult &&
                              'highlighted-result-with-mouse'}`
                          "
                          v-for="(users, key) in options"
                          :key="key"
                          @click="redirectToUser(users.value)"
                          @mouseover="highlightedResult = -1"
                        >
                          <div class="all-users__user--image">
                            <img :src="users.icon" />
                          </div>
                          <h4 class="all-users__user--name">
                            {{ users.label }}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div v-if="fetching">
                      <skeleton-loader
                        v-for="index in 3"
                        :key="index"
                      ></skeleton-loader>
                    </div>
                    <h4
                      v-else-if="!fetching && options.length === 0"
                      class="no-found"
                    >
                      No results found!
                    </h4>
                  </div>
                </a-col>
              </a-row>
            </div>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </div>
</template>

<script>
import SkeletonLoader from "../BaseComponents/ConnectionCardSkeleton.vue";
import { mapActions } from "vuex/dist/vuex.cjs.js";
export default {
  data() {
    return {
      openDropDown: false,
      searchQuery: "",
      options: [],
      fetching: false,
      highlightedResult: -1,
    };
  },
  components: {
    SkeletonLoader,
  },
  watch: {
    searchQuery(val) {
      this.fetchAllUsers(val);
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  methods: {
    ...mapActions({
      setActiveTabKey: 'profile/setActiveTabKey',
      setSelectedUser: 'profile/setSelectedUser'
    }),
    openSearchBar() {
      this.openDropDown = true;
    },
    handleClickOutside(event) {
      const element = this.$el;
      if (!element.contains(event.target)) {
        this.openDropDown = false;
      }
    },
    debounce(method, timer) {
      if (this.$_debounceTimer !== null) {
        clearTimeout(this.$_debounceTimer);
      }
      this.$_debounceTimer = setTimeout(() => {
        method();
      }, timer);
    },
    async fetchAllUsers(value) {
      try {
        this.options = [];
        this.fetching = true;
        this.debounce(async () => {
          const payload = {
            full_name: value,
          };
          const response = await this.$store.dispatch(
            "profile/getUsersList",
            payload
          );
          if (response.status == 200) {
            response.data.map((val) => {
              if (val.profile) {
                // let userList = {
                //   label: val.profile.full_name,
                //   value: val.id,
                //   key: val.email,
                //   icon: val.profile.profile_picture,
                // };
                let userList = {
                  label: val.profile.full_name,
                  value: {...val.profile, id:val.id},
                  key: val.email,
                  icon: val.profile.profile_picture,
                };
                this.options.push(userList);
                this.options = this.options.slice(0, 5);
                return userList;
              }
            });
            this.fetching = false;
          }
        }, 1000);
      } catch (err) {
        console.log(err);
      }
    },
    redirectToUser(user) {
      this.searchQuery = ''
      this.openDropDown = false
      this.setActiveTabKey('experience')
      this.setSelectedUser(user)
      this.$router.push({
        path: `/user/${user.username}/`,
      });
      this.getUserInformation();
    },
    async getUserInformation() {
      
      try {
        await this.$store.dispatch("profile/fetchUserProfile");
        await this.$store.dispatch("profile/loadExperiences");
        await this.$store.dispatch("profile/getAddedLanguages");
        await this.$store.dispatch("profile/fetchPhotoResume");
        this.searchQuery = ''
      } catch (err) {
        console.log(err);
      }
    },
    navigateResults(e) {
      if (e.key === "ArrowUp") {
        this.highlightedResult >= 0 ? (this.highlightedResult -= 1) : null;
      } else if (e.key === "ArrowDown") {
        this.highlightedResult !== this.options.length - 1
          ? (this.highlightedResult += 1)
          : null;
      } else if (e.key === "Enter") {
        if (this.highlightedResult === this.options.length) {
          this.searchArticle();
          e.preventDefault();
          this.highlightedResult = 0;
        } else if (this.highlightedResult >= 0) {
          this.redirectToUser(this.options[this.highlightedResult].value);
          this.highlightedResult = 0;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.connections-search-bar {
  margin-bottom: 3rem;
  width: 100%;
  position: relative;
  &__header {
    width: 100%;
    margin: 0 0 2.5rem;
    display: flex;
    align-items: center;
    background-color: #ececec;
    padding: 0 1.7rem;
    border-radius: 100px;
    .icon {
      font-size: 1.6rem;
      opacity: 0.6;
      color: $color-black;
    }
    .search-input {
      font-size: 1.6rem;
      font-family: $font-primary;
      line-height: normal;
      padding: 1.1rem 1.4rem;
      border: none;
      outline: none;
      width: 100%;
      background-color: transparent;
      &::placeholder {
        color: $color-black;
        opacity: 0.4;
      }
    }
  }
  &__body {
    background-color: $color-white;
    width: 100%;
    padding: 2rem 0 0;
    border-radius: 0.5rem;
    &--data {
      .title {
        font-size: 2rem;
        font-family: $font-primary-bold;
        color: $color-black;
        margin: 0 1.5rem 1.5rem;
      }
      .all-users {
        &__user {
          display: flex;
          align-items: center;
          margin-bottom: 0.5rem;
          padding: 1rem 2rem;
          cursor: pointer;
          transition: 0.4s;
          &--image {
            width: 3rem;
            height: 3rem;
            margin-right: 1rem;
            background-color: rgba(128, 128, 128, 0.195);
            img {
              width: 100%;
              height: 100%;
            }
          }
          &--name {
            font-size: 1.6rem;
            font-family: $font-primary-bold;
            color: $color-black;
            margin-bottom: 0;
          }
        }
      }
      .ant-skeleton {
        margin: 1rem 1.5rem !important;
      }
      .no-found {
        text-align: center;
        font-size: 1.4rem;
        font-family: $font-primary;
        color: $color-black;
        opacity: 0.6;
        margin: 0 0 2rem;
      }
    }
  }
  &__body::-webkit-scrollbar {
    width: 0.5rem;
  }

  &__body::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 0.8rem;
  }

  &__body::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 0.8rem;
    height: 5rem;
  }
}
</style>
