<template>
  <a-modal
    :visible="visible"
    :footer="null"
    @cancel="closeModal"
    wrapClassName="add-edit-folder-modal"
  >
    <div class="add-edit-folder-container">
      <h1 class="add-edit-folder-container__title">
        {{ isEditMode ? 'Edit' : 'Add New' }} Category
      </h1>
      <input
        type="text"
        v-model="selectedFolder"
        class="add-edit-folder-container__input"
        placeholder="Enter category name"
      />
      <p v-if="error" class="add-edit-folder-container__error">{{ error }}</p>
      <a-button
        :loading="loading"
        class="add-edit-folder-container__btn"
        @click="takeAction"
      >
        {{ isEditMode ? 'Save' : 'Add' }}
      </a-button>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    selectedFolderName: String,
    selectedFolderId: String,
    loading: Boolean,
    isEditMode: Boolean,
  },
  emits: ["handle-close", "save-record"],
  data() {
    return {
      selectedFolder: "",
      error: "",
    };
  },
  watch: {
    selectedFolderName() {
      this.selectedFolder = this.selectedFolderName || "";
    },
    visible: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.selectedFolder = this.selectedFolderName || "";
        }
      },
    }
  },
  methods: {
    closeModal() {
      this.$emit("handle-close", false);
      this.error = "";
      this.selectedFolder = this.selectedFolderName || ""
    },
    takeAction() {
      if (this.selectedFolder.trim() === "") {
        this.error = "This field cannot be empty";
        return false;
      } else {
        this.error = "";
      }
      const payload = {
        folderName: this.selectedFolder,
        id: this.selectedFolderId,
      };
      this.$emit("save-record", payload);
    },
  },
};
</script>

<style lang="scss">
.add-edit-folder-modal {
  .ant-modal {
    width: 50rem !important;
    .ant-modal-header {
      padding: 0;
      border: none;
      margin-bottom: 1rem;
    }
    .ant-modal-content {
      padding: 3rem;
      border-radius: 0;
      position: relative;
      .ant-modal-close {
        margin-right: 3rem;
        margin-top: 3rem;
        height: fit-content;
        width: fit-content;
        .ant-modal-close-x {
          width: 2rem;
          height: 2rem;
          line-height: 2rem;
          margin-right: 0;
          margin-top: 0;
          .anticon-close {
            svg {
              width: 2rem;
            }
          }
        }
      }
      .ant-modal-body {
        .add-edit-folder-container {
          &__title {
            font-size: 2.4rem;
            font-family: $font-secondary-bold;
            line-height: normal;
            margin-bottom: 2rem;
          }
          &__error {
            font-size: 1.4rem;
            font-family: $font-primary;
            color: red;
          }
          &__input {
            background-color: $color-white;
            border: 1px solid rgba(0, 0, 0, 0.2);
            outline: none;
            height: 4rem;
            font-size: 1.6rem;
            font-family: $font-primary;
            color: $color-black;
            padding: 0 1rem;
            width: 100%;
            margin-bottom: 1rem;
            &::placeholder {
              opacity: 0.5;
            }
          }
          &__btn {
            width: 10rem;
            background-color: $color-primary;
            border: none;
            height: 4rem;
            display: block;
            margin-left: auto;
            font-size: 1.6rem;
            font-family: $font-primary;
            color: $color-white;
            margin-top: 3rem;
            span {
              line-height: normal;
            }
            .anticon {
              .anticon-spin {
                font-size: 2rem;
                color: $color-primary;
              }
            }
          }
        }
      }
    }
  }
}
</style>
