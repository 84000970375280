<template>
  <profile-skeleton v-if="isProfileLoading"> </profile-skeleton>
  <div v-else class="user-information">
    <div
      class="user-information__cover-image"
      :style="{ backgroundImage: `url('${getUserInfo.profile.cover_photo}')` }"
    >
      <button
        class="user-information__cover-image--upload-btn"
        @click="showUploadImageModal('cover')"
        v-if="isLoggedInUser"
      >
        <img
          class="icon"
          src="../../assets/icons/camera-icon-stoke.svg"
          alt="upload image"
          width="auto" 
          height="auto"
        />
      </button>
    </div>
    <div class="user-information__info-continer">
      <div class="user-information__info-continer--info">
        <div class="user-details">
          <div class="user-image">
            <img :src="getUserInfo.profile.profile_picture" alt="User Image" class="image" />
            <div
              @click="showUploadImageModal('profile')"
              class="camera-icon"
              v-if="isLoggedInUser"
            >
              <img
                class="icon"
                src="../../assets/icons/camera-icon.png"
                alt="upload image"
              />
            </div>
          </div>
          <div class="buttons">
            <send-connection-request
              :userId="getUserInfo.id"
              :isLoggedUser="isLoggedInUser"
            ></send-connection-request>
            <a-dropdown
             v-if="String(getUserInfo.id) === String(local_id)"
              :trigger="['click']"
              placement="bottomLeft"
              class="buttons__dropdown"
            >
              <a class="ant-dropdown-link">
                <i class="icon icon-vertical_three-dots"></i>
              </a>
              <template #overlay>
                <a-menu>
                  <a-menu-item @click.prevent="shareProfile()" key="0">
                    <a>Share Profile</a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </div>
        <div class="user-about-name">
          <div class="user-about-name__name">
            <div
              v-show="fullNameVisibilty"
              class="user-about-name__name--user-name"
            >
              <h3>{{ getUserInfo.profile.full_name }}</h3>
              <i
                v-if="isLoggedInUser"
                @click="editFullName()"
                class="icon-edit icon icon-edit_pen"
              ></i>
            </div>
            <div
              v-show="editFullNameText"
              class="user-about-name__name--edit-name"
            >
              <input type="text" v-model="getUserInfo.profile.full_name" />
              <div class="buttons">
                <a-button
                  @click="updateFullName()"
                  class="btn"
                  :loading="onNameUpdateLoader"
                >
                  Save
                </a-button>
                <a-button @click="cancelFullName()" class="btn">
                  Cancel
                </a-button>
              </div>
            </div>
          </div>
          <div class="mission">
            <div class="mission__header">
              <h4 class="mission__header--title">
                About me
                <i
                  v-if="isLoggedInUser"
                  @click="editMission()"
                  class="icon-edit icon icon-edit_pen"
                ></i>
              </h4>
              <p v-show="missionVisibilty" class="mission__header--text">
                {{ getUserInfo.profile.mission }}
              </p>
            </div>
            <div
              v-show="editMissionText"
              class="mission__edit-about"
              v-if="isLoggedInUser"
            >
              <textarea
                name=""
                id=""
                rows="2"
                v-model="getUserInfo.profile.mission"
              ></textarea>
              <span class="mission-text-count"
                >{{ getUserInfo.profile.mission.length }}/{{ remainingChar }}</span
              >
              <div class="buttons">
                <a-button
                  :class="{
                    'disable-save-btn': getUserInfo.profile.mission.length >= missionMaxLenght,
                  }"
                  @click="updateMission()"
                  class="btn"
                  :loading="loading"
                >
                  Save
                </a-button>
                <a-button @click="cancelMission()" class="btn">
                  Cancel
                </a-button>
              </div>
            </div>
          </div>
          <div class="contact-info">
            <p class="contact-info__loaction">Karachi, Sindh, Pakistan</p>
            <button class="contact-info__btn" @click="showContactInfoModal()">Contact info</button> 
          </div>
          <!-- <p class="latest-searches">
            <span>Latest Searches: </span> Web 3.0, Design, Technology, Defence
            Technology, Space Station, Black Holes
          </p> -->
        </div>
      </div>
      <div class="user-information__info-continer--expertise">
        <div class="header">
          <h3 v-if="getUserInfo.account_type === 'basic'">Subject Matter Expertise (SME)</h3>
          <h3 v-if="getUserInfo.account_type === 'organization'">Focus Areas</h3>
          <button
            class="add-btn"
            v-if="isLoggedInUser"
            @click="showExpertiseModal"
            id="addExperttiseButton"
            title="addExperttiseButton"
            >
            <i class="icon icon-add_plus"></i>
          </button>
        </div>
        <div class="all-expertise">
          <div class="expertise" v-for="expertise in topics" :key="expertise">
            <p>{{ expertise.title }}</p>
            <a-dropdown
              v-if="isLoggedInUser"
              @click.stop=""
              :trigger="['click']"
              placement="bottomRight"
              class="expertise__dropdown"
            >
              <a class="ant-dropdown-link">
                <i
                  data-cy="groupActions"
                  class="icon icon-vertical_three-dots"
                ></i>
              </a>
              <template #overlay>
                <a-menu>
                  <a-menu-item
                    @click.prevent="editExpertise(expertise)"
                    key="0"
                  >
                    <a>Edit</a>
                  </a-menu-item>
                  <a-menu-item key="1">
                    <a
                      @click="showDeleteModal(expertise.id, 'expertise')"
                      class="remove-btn"
                      >Delete</a
                    >
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
          <p class="message" v-if="user.topics.length == 0">No record added</p>
        </div>
      </div>
    </div>
  </div>
  <contact-info-modal
    :visible="contactInfoModal"
    @close-modal="closeContactInfoModal"
  >
  </contact-info-modal>
  <ImageUploadModal
    :visibleModal="visibleUploadImgModal"
    :modalText="modalHeaderText"
    :horRatio="horizontalRatio"
    :verRatio="verticalRatio"
    @closeModal="closeUploadImgModal"
    @imageUploadedSuccessfully="imageUploaded"
  />
  <ShareProfileQRCodeModal
    :visible="visibleShareProfileQRCodeModal"
    @closeModal="closeShareProfileModal"
  />
  <add-topic-modal
    ref="expertise-modal"
    :action="action"
    :visible="showAddTopicModal"
    @close-modal="showAddTopicModal = false"
    :footer="null"
  ></add-topic-modal>
  <delete-modal
    :visible="deleteModalVisible"
    @handle-close="deleteModalVisible = false"
    title="Are you sure you want to delete this record?"
    confirmText="Yes, Delete"
    cancelText="No, Don't Delete"
    message="The record will be permanently deleted."
    @delete-record="deleteRecord"
    :loading="deleteloading"
  ></delete-modal>
  <message-toaster
    :visible="showMessageModal"
    :message="message"
    @handle-close="showMessageModal = false"
  >
  </message-toaster>
</template>

<script>
import Cookies from 'js-cookie';
import ProfileSkeleton from "./SkeletonLoaders/ProfileSkeletonLoader.vue";
import ImageUploadModal from "./ImageUploadModal.vue";
import ShareProfileQRCodeModal from "./ShareProfileQRCodeModal.vue";
import SendConnectionRequest from "../ProfilePageComponents/SendConnectionRequest.vue";
import AddTopicModal from "../ProfilePageComponents/AddTopicModal.vue";
import DeleteModal from "../BaseComponents/DeleteModal.vue";
import MessageToaster from "../BaseComponents/MessageToaster.vue";
import ContactInfoModal from "./ContactInfoModal.vue"

export default {
  components: {
    ProfileSkeleton,
    ImageUploadModal,
    ShareProfileQRCodeModal,
    SendConnectionRequest,
    AddTopicModal,
    DeleteModal,
    MessageToaster,
    ContactInfoModal
  },
  computed: {
    isProfileLoading() {
      return this.$store.getters["profile/getProfileSkeletonLoader"];
    },
    getUserInfo(){
      return this.$store.getters["profile/getSelectedUserProfile"];
    },
    user() {
      return this.$store.getters["profile/getProfileInfo"];
    },
    userFullName() {
      return this.$store.getters["profile/getProfileInfo"].full_name;
    },
    userMission() {
      return this.$store.getters["profile/getProfileInfo"].mission;
    },
    isLoggedInUser() {
      return this.$store.getters["profile/isLoggedinUser"];
    },
    userId() {
      return this.$store.getters["auth/getUserId"];
    },
    topics() {
      return this.$store.getters["profile/getTopics"];
    },
  },
  async mounted() {
    await this.handleConnctionRequest();
    this.local_id = Cookies.get('user_id');
    this.$store.commit("profile/updateTimeline");
    const payload = {
      user_id: this.$route.params.id,
    };
    this.emitter.on("visibleMessageToaster", (message) => {
      this.showMessageModal = message.showMessageModal;
      this.message = {
        title: message.title,
        type: message.type,
      };
    });
  },
  data() {
    return {
      local_id:null,
      visibleUploadImgModal: false,
      horizontalRatio: 0,
      verticalRatio: 0,
      modalHeaderText: "",
      imgReqPending: false,
      visibleShareProfileQRCodeModal: false,
      showAddTopicModal: false,
      deleteModalVisible: false,
      action: "",
      deleteloading: false,
      showMessageModal: false,
      message: {},
      selectedRecordId: null,
      recordType: "",
      profile: {
        myMissionText: "",
        userName: "",
        id: "",
        profilePic: "",
      },
      onNameUpdateLoader: false,
      editFullNameText: false,
      fullNameVisibilty: true,
      missionVisibilty: true,
      editMissionText: false,
      missionMaxLenght: 255,
      remainingChar: 255,
      loading: false,
      contactInfoModal: false,
    };
  },
  watch: {
    myMissionText() {
      this.remainingChar = this.missionMaxLenght - this.getUserInfo.profile.mission.length;
    },
    async '$route.params.username'(newId,oldId) {
      if(newId){
        await this.getUsersInfo()
        await this.handleConnctionRequest();
      }
    }
  },
  methods: {
    async handleConnctionRequest() {
      try {
        const payload = {
          to_user: this.$route.params.id,
        };
        const response = await this.$store.dispatch(
          "profile/handleConnctionRequest",
          payload
        );
        // if (response.status == 200) {
        //   // console.log(response.data);
        // }
      } catch (err) {
        console.log(err);
      }
    },
    // Edit Name
    editFullName() {
      this.editFullNameText = true;
      this.fullNameVisibilty = false;
    },
    saveFullName() {
      this.fullNameVisibilty = true;
      this.editFullNameText = false;
    },
    cancelFullName() {
      this.getUserInfo.profile.full_name = this.userFullName;
      this.editFullNameText = false;
      this.fullNameVisibilty = true;
    },
    async updateFullName() {
      this.onNameUpdateLoader = true;
      const payload = {
        profile: {
          full_name: this.getUserInfo.profile.full_name,
        },
      };
      try {
        const response = await this.$store.dispatch(
          "profile/updateProfileInfo",
          payload
        );
        if (response.status === 200) {
          this.onNameUpdateLoader = false;
          this.saveFullName();
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    // Edit Mission
    editMission() {
      this.editMissionText = true;
      this.missionVisibilty = false;
    },
    cancelMission() {
      this.getUserInfo.profile.mission = this.userMission;
      this.editMissionText = false;
      this.missionVisibilty = true;
    },
    saveMission() {
      this.missionVisibilty = true;
      this.editMissionText = false;
    },
    async updateMission() {
      this.loading = true;
      let payload = {
        profile: {
          mission: this.getUserInfo.profile.mission,
        },
      };
      try {
        const response = await this.$store.dispatch(
          "profile/updateProfileInfo",
          payload
        );
        if (response.status === 200) {
          this.saveMission();
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    showUploadImageModal(modalToBeOpen) {
      if (modalToBeOpen === "cover") {
        this.horizontalRatio = 2;
        this.verticalRatio = 10;
        this.modalHeaderText = "Upload Cover Image";
      } else {
        this.horizontalRatio = 12;
        this.verticalRatio = 12;
        this.modalHeaderText = "Upload Profile Image";
      }
      this.visibleUploadImgModal = true;
    },
    closeUploadImgModal() {
      this.visibleUploadImgModal = false;
    },
    imageUploaded(value) {
      if (value.msg === "cover") {
        this.getUserInfo.profile.cover_photo = value.img;
      } else {
        this.getUserInfo.profile.profile_picture = value.img;
      }
      this.closeUploadImgModal();
    },
    hidedeleteModal() {
      this.deleteModalVisible = false;
    },
    shareProfile() {
      this.visibleShareProfileQRCodeModal = true;
    },
    closeShareProfileModal() {
      this.visibleShareProfileQRCodeModal = false;
    },
    async deleteRecord() {
      this.deleteloading = true;
      if (this.recordType == "expertise") {
        this.deleteTopicExpertise();
      }
    },
    showExpertiseModal() {
      this.action = "add";
      this.showAddTopicModal = true;
    },
    showDeleteModal(id, type) {
      this.recordType = type;
      this.selectedRecordId = id;
      this.deleteModalVisible = true;
    },
    editExpertise(expertise) {
      this.action = "edit";
      this.showAddTopicModal = true;
      this.$refs["expertise-modal"].selectTopic(expertise);
    },
    async deleteTopicExpertise() {
      try {
        await this.$store
          .dispatch("profile/deleteTopic", this.selectedRecordId)
          .then(() => {
            this.message = {
              title: "Deleted Successfully",
              desc: "",
              type: "success",
            };
            this.hidedeleteModal();
            this.showMessageModal = true;
          });
      } catch (err) {
        this.message = {
          title: "Something went wrong",
          desc: "Please Try Again later. Sorry for the inconvenience",
          type: "failure",
        };
        this.showMessageModal = true;
        this.hidedeleteModal();
      }
      this.selectedRecordId = null;
      this.deleteloading = false;
    },
    async getUsersInfo() {
      // this.skeletonLoading = true;
      this.$store.commit('profile/setUserProfileLoader', true);
      try {
        await this.$store.dispatch(
          "profile/fetchUserProfile"
        );
        // if (response.status === 200) {
        //   console.clear()
        //   console.log('getUsersInfo',response);
          
        //   // this.user.full_name = response.data.profile.full_name;
        //   // this.user.mission = response.data.profile.mission;
        //   // this.profile.id = response.data.profile.id;
        //   // this.profile.profilePic = response.data.profile.profile_picture;
        //   // this.userisAuthenticated();
        // }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit('profile/setUserProfileLoader', false);
    },
    closeContactInfoModal() {
      this.contactInfoModal = false
    },
    showContactInfoModal() {
      this.contactInfoModal = true
    }
  },
};
</script>

<style lang="scss">
.user-information {
  &__cover-image {
    height: 14.5rem;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    background-color: #ececec;
    position: relative;
    @include respond(tab-port) {
      height: 20rem;
    }
    &--upload-btn {
      position: absolute;
      right: 1.2rem;
      z-index: 2;
      top: 1.2rem;
      height: 4.2rem;
      width: 4.2rem;
      background-color: #d9d9d9ae;
      border-radius: 100% !important;
      border: none;
      outline: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 1.6rem;
        height: auto;
      }
      @include respond(tab-port) {
        height: 5rem;
        width: 5rem;
        img {
          width: 2rem;
        }
      }
    }
  }
  &__info-continer {
    position: relative;
    z-index: 1;
    border: 1px solid $color-dark-grey-5;
    border-end-start-radius: 2rem;
    border-end-end-radius: 2rem;
    border-top: none;
    &--info {
      padding: 5.5rem 2.6rem 2.4rem;
      position: relative;
      margin-top: -13rem;
      @include respond(phone-x-small) {
        padding-top: 8rem;
      }
      .user-details {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .user-image {
          width: 15.8rem;
          height: 15.8rem;
          border-radius: 100%;
          z-index: 1;
          position: relative;
          transition: all 0.2s ease;
          .image {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 100%;
            border: 4px solid $color-white;
            background-color: rgba(128, 128, 128, 0.212);
          }
          &:hover {
            .camera-icon {
              display: flex;
            }
          }
          .camera-icon {
            display: none;
            align-items: center;
            justify-content: center;
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 100%;
            background-color: rgba(0, 0, 0, 0.438);
            border: 4px solid $color-white;
            top: 0;
            cursor: pointer;
            .icon {
              width: 3rem;
            }
          }
        }
        .buttons {
          margin-bottom: 2rem;
          display: flex;
          align-items: center;
          gap: 1.5rem;
          @include respond(phone-x-small) {
            margin-bottom: 4rem;
          }
          &__dropdown {
            height: fit-content;
            .icon {
              font-size: 1.8rem;
              color: $color-black;
              @include respond(tab-port) {
                font-size: 2.2rem;
              }
            }
          }
        }
      }
      .user-about-name {
        margin-top: 1.6rem;
        max-width: 102rem;
        &__name {
          &--user-name {
            display: flex;
            align-items: center;
            width: fit-content;
            margin-bottom: 1.6rem;
            h3 {
              color: $color-black;
              font-size: 2.4rem;
              font-family: $font-primary-medium;
              margin-bottom: 0;
              line-height: 2.8rem;
              margin-bottom: 0;
              @include respond(tab-port) {
                font-size: 3.4rem;
                line-height: 4rem;
              }
            }
            .icon {
              font-size: 1.5rem;
              color: $color-black;
              margin-left: 1rem;
              cursor: pointer;
              @include respond(tab-port) {
                font-size: 2rem;
              }
            }
          }
          &--edit-name {
            margin-bottom: 1.6rem;
            input {
              color: $color-black;
              font-size: 1.6rem;
              font-family: $font-secondary;
              border: 1px solid hsla(0, 0%, 43.9%, 0.2);
              width: 100%;
              padding: 0.8rem 1rem;
              outline: none;
              border-radius: 0.5rem !important;
              background-color: $color-white;
              @include respond(tab-port) {
                font-size: 2.2rem;
                line-height: 2.8rem;
                padding: 1.2rem 1.5rem;
              }
            }
          }
        }
        .mission {
          width: fit-content;
          margin-bottom: 2rem;
          width: 100%;
          &__header {
            &--title {
              color: $color-black;
              font-size: 1.8rem;
              font-family: $font-primary-medium;
              margin-bottom: 1rem;
              line-height: 2.4rem;
              width: fit-content;
              display: flex;
              align-items: center;
              .icon {
                font-size: 1.5rem;
                color: $color-black;
                margin-left: 1rem;
                cursor: pointer;
              }
              @include respond(tab-port) {
                font-size: 2.2rem;
                line-height: 2.8rem;
                .icon {
                  font-size: 1.8rem;
                }
              }
            }
            &--text {
              color: $color-black;
              font-size: 1.6rem;
              font-family: $font-primary;
              margin-bottom: 0;
              line-height: 2rem;
              @include respond(tab-port) {
                font-size: 1.8rem;
                line-height: 2.4rem;
              }
            }
          }
          &__edit-about {
            textarea {
              color: $color-black;
              font-size: 1.6rem;
              font-family: $font-secondary;
              border: 1px solid hsla(0, 0%, 43.9%, 0.2);
              width: 100%;
              padding: 0.8rem 1rem;
              outline: none;
              border-radius: 0.5rem !important;
              background-color: $color-white;
              resize: none;
              height: fit-content;
              @include respond(tab-port) {
                font-size: 1.8rem;
                line-height: 2.4rem;
                padding: 1.2rem 1.5rem;
              }
              &::-webkit-scrollbar {
                height: 0.3rem;
                width: 0.3rem;
                @include respond(phone-x-small) {
                  display: none;
                }
              }
              &::-webkit-scrollbar-track {
                background-color: #e8e8e8;
                border-radius: 0.8rem;
                height: 0.5rem;
                width: 0.3rem;
              }

              &::-webkit-scrollbar-thumb {
                background-color: $color-primary;
                border-radius: 0.8rem;
              }
            }
            .mission-text-count {
              display: block;
              margin-left: auto;
              width: fit-content;
              font-size: 1.2rem;
              line-height: 1.4rem;
              font-family: $font-primary;
              @include respond(tab-port) {
                font-size: 1.6rem;
                line-height: 2rem;
              }
            }
          }
        }
        // .latest-searches {
        //   color: $color-black;
        //   font-size: 1.6rem;
        //   font-family: $font-primary;
        //   margin-bottom: 0;
        //   line-height: 2rem;
        //   margin-bottom: 1.7rem;
        //   max-width: 102rem;
        //   overflow: hidden;
        //   display: -webkit-box;
        //   -webkit-line-clamp: 2;
        //   /* number of lines to show */
        //   line-clamp: 2;
        //   -webkit-box-orient: vertical;
        //   span {
        //     font-family: $font-primary-medium;
        //   }
        //   &:last-child {
        //     margin-bottom: 0;
        //   }
        // }
        .contact-info {
          display: flex;
          align-items: center;
          &__loaction {
            color: $color-black;
            font-size: 1.6rem;
            font-family: $font-primary;
            margin-bottom: 0;
            line-height: 1.8rem;
            opacity: 0.6;
            position: relative;
            margin-right: 1.3rem;
            &::before {
              content: "\00B7";
              right: -1rem;
              position: absolute;
              font-size: 2.5rem;
              top: 50%;
              transform: translateY(-50%);
            }
            @include respond(tab-port) {
              font-size: 1.8rem;
              line-height: 2.4rem;
            }
          }
          &__btn {
            border: none;
            background-color: transparent;
            font-size: 1.6rem;
            line-height: normal;
            outline: none;
            cursor: pointer;
            color: $color-primary;
            font-family: $font-primary-medium;
            @include respond(tab-port) {
              font-size: 1.8rem;
              line-height: 2.4rem;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-top: 0.8rem;
          .btn {
            color: $color-black;
            background-color: #e1e1e1;
            font-family: $font-primary-bold;
            border: none;
            padding: 0;
            cursor: pointer;
            width: 6.5rem;
            height: 3rem;
            font-size: 1.2rem;
            text-align: center;
            border-radius: 0.5rem !important;
            .anticon {
              .anticon-spin {
                font-size: 1.6rem !important;
              }
            }
            @include respond(tab-port) {
              font-size: 1.8rem;
              height: 4rem;
              width: 8rem;
              margin-top: 1rem;
              .anticon {
                .anticon-spin {
                  font-size: 1.8rem !important;
                }
              }
            }
          }
          .btn:first-child {
            background-color: $color-primary;
            margin-right: 1.5rem;
            color: $color-white;
          }
          .disable-save-btn {
            pointer-events: none !important;
            opacity: 0.5 !important;
          }
        }
      }
    }
    &--expertise {
      border-top: 1px solid $color-dark-grey-5;
      padding: 2.2rem 2.6rem;
      .header {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        h3 {
          color: $color-black;
          font-size: 1.8rem;
          font-family: $font-primary-medium;
          margin-bottom: 0;
          line-height: 2.4rem;
          margin-bottom: 1.5rem;
          @include respond(tab-port) {
            font-size: 2.2rem;
            line-height: 2.8rem;
          }
        }
        .add-btn {
          background-color: $color-primary;
          height: 4.2rem;
          width: 4.2rem;
          border: none;
          outline: none;
          border-radius: 100% !important;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin-left: auto;
          .icon {
            color: $color-white;
            font-size: 1.6rem;
          }
        }
      }
      .all-expertise {
        overflow-x: auto;
        width: 100%;
        overflow-y: hidden;
        height: fit-content;
        margin-left: -0.4rem;
        display: flex;
        align-items: center;
        .expertise {
          margin: 0 0.5rem;
          border-radius: 100px !important;
          height: 2.9rem;
          padding: 0.5rem 1.6rem;
          background-color: #d0dbff;
          border: 1px solid #3764fb;
          transition: all 0.3s;
          position: relative;
          cursor: default;
          width: max-content;
          display: flex;
          align-items: center;
          white-space: nowrap;
          justify-content: center;
          p {
            font-size: 1.2rem;
            line-height: 1.4rem;
            color: $color-black;
            margin-bottom: 0;
            font-family: $font-primary;
          }
          &__dropdown {
            margin-left: 1rem;
            margin-right: -0.6rem;
            .icon {
              color: $color-black;
              font-size: 1.2rem;
            }
          }
          @include respond(tab-port) {
            height: 3.5rem;
            p {
              font-size: 1.6rem;
              line-height: 2rem;
            }
            &__dropdown {
              margin-left: 1.5rem;
              .icon {
                font-size: 1.4rem;
              }
            }
          }
        }
        &::-webkit-scrollbar {
          height: 0.5rem;
          width: 0.3rem;
          @include respond(phone-x-small) {
            display: none;
          }
        }
        &::-webkit-scrollbar-track {
          background-color: #e8e8e8;
          border-radius: 0.8rem;
          height: 0.5rem;
          width: 0.3rem;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $color-primary;
          border-radius: 0.8rem;
        }
        .message {
          color: $color-black;
          font-size: 1.4rem;
          margin-bottom: 0;
          line-height: normal;
          font-family: $font-primary;
          margin-left: 0.5rem;
        }
      }
    }
  }
}
</style>
