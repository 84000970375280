<template>
  <a-skeleton
    active
    :avatar="{ shape: 'square', size: 50 }"
    :title="{ width: '100%' }"
    :paragraph="{ rows: 1, width: ['40%'] }"
    class="connection-skeleton-loader"
  />
</template>

<style lang="scss">
.connection-skeleton-loader {
  margin: 2rem 0 !important;
  display: flex;
  align-items: center;

  .ant-skeleton-header {
    margin-bottom: 1rem;
    padding-right: 1rem;

    .ant-skeleton-avatar {
      width: 4rem !important;
      height: 4rem !important;
      border-radius: 100%;
    }
  }

  .ant-skeleton-content {
    padding: 0.5rem 0;
    .ant-skeleton-title {
      height: 2.5rem;
      margin: 0;
    }

    .ant-skeleton-paragraph {
      margin: 0 !important;
      li {
        height: 1.5rem;
        margin: 0.5rem 0;
      }
    }
  }
}
</style>
