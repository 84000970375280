<template>
  <a-modal
    :visible="visibleModal"
    :title="modalText"
    :maskClosable="false"
    @cancel="closeModal"
    :footer="null"
    wrapClassName="upload-picture-modal"
    :zIndex="4444"
  >
    <image-upload
      @upload-photo="uploadPhoto"
      :horRatio="horRatio"
      :verRatio="verRatio"
      :loading="imgReqPending"
    ></image-upload>
  </a-modal>
</template>

<script>
import ImageUpload from "./ImageUpload.vue";

export default {
  components: {
    ImageUpload,
  },
  data() {
    return {
      imgReqPending: false,
    };
  },
  props: {
    visibleModal: Boolean,
    modalText: String,
    horRatio: Number,
    verRatio: Number,
  },
  computed: {
    userId() {
      return this.$store.getters["profile/getProfileInfo"].id;
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },

    async uploadPhoto(img, fileType, fileName) {
      try {
        this.imgReqPending = true;
        const res = await fetch(img);
        const buf = await res.arrayBuffer();
        const file = new File([buf], fileName, { type: fileType });
        const form = new FormData();
        form.append("id", this.userId);

        let response;
        let imageUploadedFor = "";

        if (this.modalText === "Upload Cover Image") {
          form.append("cover_photo", file);
          response = await this.$store.dispatch(
            "profile/updateCoverPhoto",
            form
          );
          imageUploadedFor = "cover";
        } else {
          console.log(file);
          form.append("profile_picture", file);
          response = await this.$store.dispatch(
            "profile/updateProfilePic",
            form
          );
          imageUploadedFor = "profile";
        }

        if (response.status === 200) {
          const payload = {
            img: img,
            msg: imageUploadedFor,
          };
          this.$emit("image-uploaded-successfully", payload);
        }
      } catch (err) {
        console.log(err);
      }
      this.imgReqPending = false;
    },
  },
};
</script>

<style lang="scss">
.upload-picture-modal {
  .ant-modal {
    width: 60rem !important;
    top: 10rem;
    .ant-modal-content {
      .ant-modal-header {
        padding: 3rem 3rem;
        border-bottom: 1px solid rgba(135, 149, 157, 0.3);
        margin-bottom: 0;
        background-color: transparent;
        .ant-modal-title {
          font-size: 2.5rem;
          font-family: $font-secondary-bold;
        }
      }
      .ant-modal-close {
        margin: 2.7rem 2.5rem 0 0;
        .ant-modal-close-x {
          padding: 0.5rem;
          width: fit-content;
          height: fit-content;
          line-height: normal;
          .anticon {
            color: $color-black;
          }
        }
      }
    }
  }
}
</style>
