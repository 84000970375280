<template>
    <a-modal width="50rem" @cancel="closeModal" class="add-language-modal-main">
        <div class="add-language-modal">
            <h1 class="add-language-modal__heading">Add Language</h1>
            <div class="add-language-modal__form-fields">
                <a-form class="modal-form" ref="formRef" :label-col="{ style: { width: '150px' } }">
                    <a-form-item label-align="left" label="Language">
                        <a-select data-cy="workExperienceLanguage" v-model:value="selectedLanguage" show-search placeholder="Select language" style="width: 100%" :options="languages">
                        </a-select>
                        <p v-if="error.language" class="error">{{ this.error.language }}</p>
                    </a-form-item>
                    <a-form-item label-align="left" label="Proficiency">
                        <a-select data-cy="workExperienceLanguageProficiency" v-model:value="selectedProficiency" placeholder="Select proficiency" style="width: 100%" :options="proficiencyLevels">
                        </a-select>
                        <p v-if="error.proficiency" class="error">
                            {{ this.error.proficiency }}
                        </p>
                    </a-form-item>
                </a-form>
                <a-button data-cy="workExperienceAddLanguage" v-if="action == 'add'" :loading="loading" class="form-btn" @click="addLanguage">
                    Add
                </a-button>
                <a-button v-else data-cy="workExperienceUpdateLanguage" :loading="loading" class="form-btn" @click="updateLanguage">
                    Update
                </a-button>
            </div>
        </div>
    </a-modal>
</template>

<script>
import MessageToaster from '../BaseComponents/MessageToaster.vue';

export default {
    props: {
        action: {
            type: String,
            required: true,
        },
    },
    emits: ["close-modal"],
    data() {
        return {
            langId: null,
            languages: [],
            message: {},
            selectedLanguage: null,
            selectedProficiency: null,
            loading: false,
            proficiencyLevels: [{
                    value: "beginner",
                    label: "Beginner",
                },
                {
                    value: "conversational",
                    label: "Conversational",
                },
                {
                    value: "fluent",
                    label: "Fluent",
                },
                {
                    value: "native",
                    label: "Native",
                },
            ],
            error: {
                language: "",
                proficiency: "",
            },
        };
    },
    async mounted() {
        await this.$store.dispatch("article/fetchLanguages");
        let languages = this.$store.getters["article/getLanguages"];
        languages.map((lang) => {
            let obj = {
                value: lang.code,
                label: lang.language,
            };
            this.languages.push(obj);
        });
    },
    methods: {
        selectLanguage(lang, languageId) {
            this.langId = languageId;
            this.selectedLanguage = lang.language;
            this.selectedProficiency = lang.proficiency;
        },
        async addLanguage() {
            if (this.selectedLanguage === "") {
                this.error.language = "Please select language";
                return;
            } else if (this.selectedProficiency === "") {
                this.error.proficiency =
                    "Please select your proficiency in the selected language";
                return;
            }
            let requestObj = {
                code: this.selectedLanguage,
                proficiency: this.selectedProficiency,
            };
            this.loading = true;
            await this.$store
                .dispatch("profile/addLanguage", requestObj)
                .then(() => {
                    this.closeModal();
                    this.message = {
                        showMessageModal: true,
                        title: "Language Added Successfully",
                        type: "success",
                    };
                    this.emitter.emit("visibleMessageToaster", this.message);
                })
                .catch(() => {
                    this.closeModal();
                    this.message = {
                        showMessageModal: true,
                        title: "Something went wrong",
                        type: "failure",
                    };
                    this.emitter.emit("visibleMessageToaster", this.message);
                });
        },
        async updateLanguage() {
            this.languages.map((val) => {
                if (val.label == this.selectedLanguage) {
                    this.selectedLanguage = val.value;
                }
            });
            let requestObj = {
                id: this.langId,
                code: this.selectedLanguage,
                proficiency: this.selectedProficiency,
            };
            this.loading = true;
            await this.$store
                .dispatch("profile/updateLanguage", requestObj)
                .then(() => {
                    this.closeModal();
                    this.message = {
                        showMessageModal: true,
                        title: "Language Updated Successfully",
                        type: "success",
                    };
                    this.emitter.emit("visibleMessageToaster", this.message);

                })
                .catch(() => {
                    this.closeModal();
                    this.message = {
                        showMessageModal: true,
                        title: "Something went wrong",
                        type: "failure",
                    };
                    this.emitter.emit("visibleMessageToaster", this.message);
                });
        },
        closeModal() {
            this.loading = false;
            this.errors = {};
            (this.selectedLanguage = null),
            (this.selectedProficiency = null),
            this.$emit("close-modal");
        },
    },
};
</script>

<style lang="scss">
.add-language-modal-main {
    .ant-modal-content {
        position: relative;
        .ant-modal-close {
            margin-right: 3rem;
            margin-top: 3rem;
            height: fit-content;
            width: fit-content;
            .ant-modal-close-x {
                width: 2rem;
                height: 2rem;
                line-height: 2rem;
                margin-right: 0;
                margin-top: 0;
                .anticon-close {
                    svg {
                        width: 2rem;
                    }
                }
            }
        }
        .ant-modal-body {
            .add-language-modal {
                padding: 3rem;
                background-color: #f6f8ff;
                border-radius: 1rem;
                &__heading {
                    font-size: 2.2rem;
                    font-family: $font-primary-bold;
                    margin-bottom: 3rem;
                    text-align: center;
                    line-height: normal;
                }
                &__form-fields {
                    .modal-form {
                        .ant-form-item {
                            margin-bottom: 2rem;
                            .ant-form-item-label {
                                padding-bottom: 0;
                                height: fit-content;
                                line-height: normal;
                                margin-bottom: 0.5rem;
                                label {
                                    font-size: 1.6rem;
                                    font-family: $font-primary;
                                    height: auto;
                                }
                            }
                            .ant-form-item-control {
                                .ant-form-item-control-input {
                                    .ant-form-item-control-input-content {
                                        .ant-select {
                                            text-align: left;
                                            .ant-select-selector {
                                                border: 1px solid rgba(112, 112, 112, 0.2);
                                                border-radius: 0.5rem !important;
                                                .ant-select-selection-search {
                                                    display: flex;
                                                    align-items: center;
                                                    .ant-select-selection-search-input {
                                                        height: auto;
                                                    }
                                                }
                                                .ant-select-selection-item {
                                                    font-size: 1.6rem;
                                                    font-family: $font-primary;
                                                    line-height: normal;
                                                }
                                                .ant-select-selection-placeholder {
                                                    font-size: 1.6rem;
                                                    font-family: $font-primary;
                                                }
                                            }
                                        }
                                        .description-textarea {
                                            resize: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .ant-btn {
                        border: none;
                        font-size: 1.6rem;
                        font-family: $font-secondary-bold;
                        cursor: pointer;
                        background-color: $color-primary;
                        color: $color-white;
                        transition: all 0.2s;
                        height: 4rem;
                        width: 10rem;
                        margin-top: 3rem;
                        margin-left: auto;
                        display: block;
                        &:hover,
                        &:active,
                        &:focus {
                            background-color: $color-primary;
                            color: $color-white;
                            border: none;
                        }
                        span {
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }
}
</style>
