<template>
  <div class="save-article-container">
    <p class="save-article-container__title">{{ title }}</p>
    <div class="save-article-container__input-field">
      <a-radio-group name="radioGroup" v-model:value="radioValue">
        <a-radio value="portfolio"
          >Save to Archives
          <p>
            Saves this article to your profile archives.
          </p></a-radio
        >
      </a-radio-group>
      <a-select
        v-model:value="visiblityType"
        style="width: 100%"
        placeholder="Select category type"
        :options="portfolioFolderType"
        :disabled="activePortfolio"
      >
      </a-select>
      <a-select
        v-model:value="folderId"
        placeholder="Save to existing category"
        :options="archiveFolders"
        :disabled="isSelectDisabled || activePortfolio"
        style="width: 100%"
      >
      </a-select>
      <a-input
        v-if="isNewFolder"
        type="text"
        v-model:value="folderName"
        placeholder="Enter category name"
        class="input"
        :disabled="activePortfolio"
      >
      </a-input>
      <p class="error" v-if="fieldErr">This field can not be empty</p>
      <div class="toggle">
        <a @click="toggleFolderSelection">
          {{ titleText }}
        </a>
      </div>
      <a-radio-group name="radioGroup" v-model:value="radioValue">
        <a-radio value="folders"
          >Save to Folders
          <p>
            Saves to folders of your choice either personal or class folders.
          </p>
        </a-radio>
      </a-radio-group>
      <a-select
        v-model:value="selectedType"
        style="width: 100%"
        placeholder="Select folder type"
        :options="folderType"
        :disabled="!activePortfolio"
      >
      </a-select>
      <a-tree-select
        v-model:value="selectedFolder"
        style="width: 100%"
        :disabled="!activePortfolio"
        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
        :tree-data="folderList"
        placeholder="Please select"
        :treeIcon="true"
        tree-default-expand-all
      >
      </a-tree-select>
      <p class="error" v-if="selectedFolderErr">This field can not be empty</p>
    </div>
    <a-button :loading="loader" @click="saveArticle(isNewFolder)" >Save</a-button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["visible", "articleId"],
  emits: ["handle-close"],
  data() {
    return {
      radioValue: "portfolio",
      title: "Save",
      folderName: undefined,
      folderId: undefined,
      loader: false,
      fieldErr: false,
      isNewFolder: false,
      titleText: "Create new category",
      selectedFolder: null,
      activePortfolio: false,
      selectedType: null,
      isTreeLoading: true,
      selectedFolderErr: false,
      visiblityType: null
    };
  },
  computed: {
    ...mapGetters({
      archiveFolderList: 'archive/archiveFolderList',
      userId: 'auth/getUserId'
    }),
    isSelectDisabled() {
      return this.isNewFolder;
    },
    archiveFolders() {
      return this.archiveFolderList.map(folder=>{ return  {label:folder.name, value:folder.id}})
    },
    isInstitute() {
      return this.$store.getters["profile/userInformation"].profile.institute;
    },
    folderType() {
      const value = [
      {
        label: 'Class Folder',
        value: 'activeClassFolder',
      },
      {
        label: 'Personal Folder',
        value: 'activePersonalFolder',
      }
      ]
      return this.isInstitute?.permission === 'admin'
        || this.isInstitute?.permission === 'faculty'
        ? value : [{ ...value[1] }];
    },
    portfolioFolderType() {
      return [
        {
          label: 'Public',
          value: 'public',
        },
        {
          label: 'Private',
          value: 'private',
        },
      ];
    },
    folderList() {
        const folders = this.$store.getters["folders/getFolderList"].map(folder => {
          const newFolder = {
            title: folder.name,
            key: folder.id,
            value: folder.id,
            children: folder.child ? folder.child.map(child => {
              const children = {
                title: child.name,
                key: folder.id,
                value: child.id,
              }
              return children;
            }) : []
          }
          return newFolder;
        });
        return folders
    },
  },
  watch: {
    visible(val) {
      if (!val) {
        this.visiblityType = null
        this.isNewFolder = false
        this.radioValue = "portfolio"
        this.selectedType = null
        this.selectedFolder = null
      }
    },
    radioValue(val) {
      if (val === 'portfolio') {
        this.activePortfolio = false;
        this.visiblityType = 'public'
      } else {
        this.activePortfolio = true;
        this.visiblityType = null
      }
    },
    selectedType(val) {
      this.getFolderTree(val);
    },
    visiblityType:{
      handler(type){
        if (type === 'public')
          this.fetchArchiveFoldersList({visibility:'public', user_id:this.userId})
        else if (type === 'private')
          this.fetchArchiveFoldersList({visibility:'private', user_id:this.userId})
      },
      immediate:true
    }
  },

  methods: {
    ...mapActions({
      fetchArchiveFoldersList: "archive/fetchArchiveFoldersList",
      archiveFolderCreate:'archive/archiveFolderCreate',
      saveArticleToArchiveFolder:'archive/saveArticleToArchiveFolder',
    }),
    async getFolderTree(tab) {
      try {
        this.isTreeLoading = true;
        let classFolderTree = 'folders/getClassFolderTree';
        let personalFolderTree = 'folders/getPersonalFolderTree'
        let apiCall = tab === 'activeClassFolder' ? classFolderTree : personalFolderTree;
        const response = await this.$store.dispatch(apiCall);
        if (response.status === 200) {
          this.isTreeLoading = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    toggleFolderSelection() {
      this.isNewFolder = !this.isNewFolder;
      this.fieldErr = false;
      this.folderName = undefined;
      this.folderId = undefined;
      if (this.isNewFolder) {
        this.titleText = "Save to existing category";
      } else {
        this.titleText = "Create new category";
      }
    },
    async saveArticle(newFolder) {
      try {
        this.loader = true;
        const { folderName, folderId, articleId, selectedFolder, radioValue, visiblityType } = this;
        let payload = {} 
        const article_details = { 
          content_type: 'articles',
          article: articleId,
        }
        if (radioValue === "portfolio") {
          if (newFolder) {
            const folderNameTrimmed = folderName?.trim() || "";
            if (!folderNameTrimmed) {
              this.fieldErr = true;
              return;
            }

            payload = {
              'name':folderName,
              'visibility':visiblityType,
              'portfolio_archive':article_details
            }
          } 
          else {
            if (!folderId) {
              this.fieldErr = true;
              return;
            }

            payload = {
              folderId: folderId,
              article_details: article_details
            }
        }

          const response =  newFolder ? await this.archiveFolderCreate(payload) :  await this.saveArticleToArchiveFolder(payload) 

          if ([200, 201].includes(response.status)) {
            this.fieldErr = false;
            this.folderName = this.folderId = undefined;
            this.visiblityType = null
            this.emitter.emit("close-article-save-modal", {
              modalVisibility: false,
              showMessageModal: true,
              title: 'Article saved successfully',
              type: 'success'
            });
            
          }

        } else {
          if (!selectedFolder) {
            this.selectedFolderErr = true;
            return;
          }

          const form = new FormData();
          form.append("article", articleId);
          form.append("content", 'article');

          const payload = {
            folder_id: selectedFolder,
            article: form,
          };

          const response = await this.$store.dispatch("folders/saveContentInFolder", payload);

          if (response.status === 201) {
            this.selectedFolderErr = false;
            this.emitter.emit("close-article-save-modal", {
              modalVisibility: false,
              showMessageModal: true,
              title: 'Article saved successfully',
              type: 'success'
            });
          }
        }
      } catch (err) {
        this.emitter.emit("close-article-save-modal", {
          modalVisibility: false,
          showMessageModal: true,
          title: err.response?.data?.[0] || 'An error occurred',
          type: 'failure'
        });
      } finally {
        this.loader = false;
      }
    }

  },
};
</script>

<style lang="scss">
.save-article-modal {
  .ant-modal {
    width: 50.6rem !important;
    top: 8rem !important;
  }
  .ant-modal-header {
    padding: 0;
    border: none;
    margin-bottom: 1rem;
  }
  .ant-modal-content {
    padding: 2.2rem 2.5rem;
    border-radius: 0;
    position: relative;
    background-color: #f6f8ff;
    .ant-modal-close {
      margin-right: 2.2rem;
      margin-top: 2.5rem;
      height: fit-content;
      width: fit-content;
      .ant-modal-close-x {
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        margin-right: 0;
        margin-top: 0;
        .anticon-close {
          svg {
            width: 2rem;
            color: $color-black;
          }
        }
      }
    }
    .ant-modal-body {
      .save-article-container {
        &__title {
          font-size: 2rem;
          font-family: $font-secondary-bold;
          line-height: normal;
          margin-bottom: 2.8rem;
          color: $color-black;
        }
        &__input-field {
          .input {
            background-color: $color-white;
            border: 1px solid #00000033;
            outline: none;
            height: 4rem;
            font-size: 1.4rem;
            font-family: $font-primary;
            color: $color-black;
            padding: 0 1rem;
            width: 100%;
            margin-bottom: 1.5rem;
            &::placeholder {
              opacity: 0.5;
              color: $color-black;
            }
          }
          .ant-radio-group {
            display: flex;
            flex-direction: column;
            .ant-radio-wrapper {
              margin-right: 0;
              font-size: 1.4rem;
              color: $color-black;
              font-family: $font-primary-medium;
              margin-bottom: 1.5rem;
              display: flex;
              opacity: 0.5;
              .ant-radio {
                display: inherit;
                .ant-radio-inner {
                  border: 0.2rem solid $color-black;
                  height: 2rem;
                  width: 2rem;
                  &::after {
                    background-color: $color-primary;
                    width: 1rem;
                    height: 1rem;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }
                }
              }
              .ant-radio-checked {
                .ant-radio-inner {
                  border: 0.2rem solid $color-black;
                  &::after {
                    background-color: $color-primary;
                  }
                }
              }
              span {
                height: fit-content;
                display: block;
                p {
                  font-size: 1.2rem;
                  margin-top: 0.4rem;
                  margin-bottom: 0;
                  color: #646464;
                  line-height: 1.4rem;
                  font-family: $font-primary;
                }
              }
            }
            .ant-radio-wrapper-checked {
              opacity: 1;
            }
          }
          .ant-select {
            margin-bottom: 1.5rem;
            .ant-select-selector {
              border: 1px solid #00000033;
              background-color: $color-white;
              outline: none;
              height: 4rem;
              box-shadow: none;
              margin-bottom: 0;
              border-radius: 0.5rem !important;
              padding: 0 1rem;
              font-size: 1.4rem;
              font-family: $font-primary;
              color: $color-black;
              .ant-select-selection-search {
                .ant-select-selection-search-input {
                  height: 100%;
                }
              }
              .ant-select-selection-placeholder {
                opacity: 0.5;
                color: $color-black;
              }
              .ant-select-selection-item {
                font-size: 1.4rem;
                font-family: $font-primary;
                color: $color-black;
              }
            }
            .ant-select-arrow {
              top: 50%;
              transform: translateY(0%);
              .anticon {
                svg {
                  width: 1.5rem;
                  color: $color-black;
                }
              }
            }
          }
          .toggle {
            margin-top: -0.5rem;
            margin-bottom: 2rem;
            line-height: 1.8rem;
            a {
              color: $color-primary;
              font-size: 1.4rem;
              font-family: $font-primary;
            }
          }
        }
        .ant-btn {
          width: 9rem;
          background-color: $color-primary;
          border: none;
          display: block;
          height: 3.6rem;
          font-size: 1.4rem;
          font-family: $font-primary-medium !important;
          color: $color-white;
          margin-top: 2.8rem;
          margin-left: auto;
          span {
            line-height: normal;
          }
          .anticon {
            .anticon-spin {
              font-size: 1.8rem;
            }
          }
        }
        

        // .error {
        //   font-size: 1.4rem;
        //   font-family: $font-primary;
        //   color: red;
        //   margin-top: 0.5rem;
        // }
        // &__container-first {
        //   .input {
        //     background-color: $color-white;
        //     border: 1px solid rgba(1, 30, 41, 0.1);
        //     outline: none;
        //     height: 4rem;
        //     font-size: 1.6rem;
        //     font-family: $font-primary;
        //     color: $color-black;
        //     padding: 0 1rem;
        //     width: 100%;
        //     margin-top: 1.5rem;
        //     &::placeholder {
        //       opacity: 0.3;
        //     }
        //   }
        //   .toggle {
        //     font-size: 1.4rem;
        //     font-family: $font-primary;
        //     color: $color-primary;
        //     cursor: pointer;
        //     margin-top: 1rem;
        //   }
        //   .ant-select {
        //     width: 100%;
        //     .ant-select-selector {
        //       width: 100%;
        //       border: 1px solid rgba(1, 30, 41, 0.1);
        //       border-radius: 0.5rem !important;
        //       box-shadow: none;
        //       .ant-select-selection-item {
        //         font-size: 1.6rem;
        //         font-family: $font-primary;
        //       }
        //       .ant-select-selection-search {
        //         .ant-select-selection-search-input {
        //           height: 100%;
        //         }
        //       }
        //       .ant-select-selection-placeholder {
        //         font-size: 1.6rem;
        //         font-family: $font-primary;
        //       }
        //     }
        //   }
        //   .btn {
        //     margin-left: auto !important;
        //   }
        // }
        // &__container-second {
        //   .buttons-container {
        //     display: flex;
        //     align-items: center;
        //     justify-content: flex-end;
        //   }
        // }

       
      }
    }
  }
}
.ant-select-dropdown {
  .ant-select-tree {
    .ant-select-tree-treenode-switcher-open,
    .ant-select-tree-treenode-switcher-close {
      .ant-select-tree-node-content-wrapper {
        .ant-select-tree-iconEle {
          background-image: url("../../assets/icons/glocal-folder-icon.svg");
          height: 2rem;
          width: 2rem;
          margin-right: 1rem;
          background-size: contain; 
        }
        .ant-select-tree-title {
          font-size: 1.4rem;
          font-family: $font-primary;
          color: $color-black;
        }
      }
    }
  }
}
</style>

