<template>
  <div class="other-information-section">
    <div v-if="isOrg === null">
      <a-skeleton
        class="other-information-section__tab-skeleton"
        active
        :title="null"
        :paragraph="{
          rows: 5,
        }"
      />
      <template v-for="index in 2" :key="index">
        <a-skeleton
          class="other-information-section__content-skeleton"
          active
          :title="{ width: '20%' }"
          :paragraph="{ rows: 5, width: ['60%', '50%', '40%', '30%', '20%'] }"
        />
      </template>
    </div>
    <template v-else>
      <ProfileSectionManager />
    </template>
  </div>
</template>
<script>
import { computed } from "vue";
import ProfileSectionManager from "./ProfileSectionManager.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ProfileSectionManager,
  },
  computed: {
    ...mapGetters("profile", ["getUserAccountType"]),
    isOrg() {
      return this.getUserAccountType;
    },
  },
};
</script>
<style lang="scss">
.other-information-section {
  border: 1px solid $color-dark-grey-5;
  border-radius: 2rem;
  margin-top: 2rem;
  &__tab-skeleton {
    .ant-skeleton-content {
      .ant-skeleton-paragraph {
        display: flex;
        align-items: center;
        padding: 2.6rem 2.6rem 0;
        margin: 0;
        gap: 1.5rem;
        border-bottom: 1px solid $color-dark-grey-5;
        border-radius: 0 !important;

        li {
          margin: 0;
          height: 4rem;
          border-radius: 1rem !important;
          border-end-start-radius: 0 !important;
          border-end-end-radius: 0 !important;
          width: 15%;
          max-width: 10rem;
        }
      }
    }
  }
  &__content-skeleton {
    padding: 1.3rem 2.6rem;
    .ant-skeleton-content {
      .ant-skeleton-title {
        height: 3rem;
        margin-bottom: 2rem;
      }
      .ant-skeleton-paragraph {
        li {
          margin: 0 0 1rem;
          height: 2rem;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
