<template>
  <div class="archive-management-sidebar">
    <div class="archive-management-sidebar__header">
      <div class="archive-management-sidebar__header--name">
        <h3>Archives</h3>
      </div>
    </div>
    <div class="archive-management-sidebar__body">
      <a-tabs
        v-model:activeKey="activeKey"
        class="archive-management-sidebar__body--tabs"
      >
        <a-tab-pane key="public">
          <template #tab>
            <div class="tab-name">
              <p>Public</p>
            </div>
          </template>
          <div>
            <button class="add-new-category-btn" @click="addNewCategory()" v-if="isLoggedInUser">
              <i class="icon icon-add_plus"></i>
              Add Category Below
            </button>
            <div class="list">
              <template v-if="archiveFoldersLoader">
                <a-skeleton
                  v-for="(skeleton, index) in 5"
                  :key="index"
                  class="folder-skeleton"
                  active
                  :title="null"
                  :paragraph="{
                    rows: 1,
                    width: ['100%'],
                  }"
                />
              </template>
              <div class="message" v-if="!archiveFoldersLoader && archiveFolders.length === 0">
                <p>No folders found.</p>
              </div>
              <template v-if="!archiveFoldersLoader && archiveFolders.length > 0">
                <div
                  class="item"
                  :class="{ clicked: activeIndex === index }"
                  v-for="(folder, index) in archiveFolders"
                  :key="index"
                  @click="selectItem(folder, index)"
                >
                  <p>{{ folder.name }}</p>
                  <a-dropdown
                    v-if="isLoggedInUser"
                    :trigger="['click']"
                    placement="bottomRight"
                    class="dropdown"
                  >
                    <a class="ant-dropdown-link" @click.stop>
                      <i class="icon icon-vertical_three-dots"></i>
                    </a>
                    <template #overlay>
                      <a-menu>
                        <a-menu-item key="0">
                          <a @click="editFolder(folder)">Edit</a>
                        </a-menu-item>
                        <a-menu-item key="1">
                          <a class="remove-btn" @click="showdeleteFolder(folder)">Remove</a>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </div>
              </template>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="private" v-if="isLoggedInUser">
          <template #tab>
            <div class="tab-name">
              <p>Private</p>
            </div>
          </template>
          <div>
            <button class="add-new-category-btn" @click="addNewCategory()" v-if="isLoggedInUser">
              <i class="icon icon-add_plus"></i>
              Add Category Below
            </button>
            <div class="list">
              <template v-if="archiveFoldersLoader">
                <a-skeleton
                  v-for="(skeleton, index) in 5"
                  :key="index"
                  class="folder-skeleton"
                  active
                  :title="null"
                  :paragraph="{
                    rows: 1,
                    width: ['100%'],
                  }"
                />
              </template>
              <div class="message" v-if="!archiveFoldersLoader && archiveFolders.length <= 0">
                <p>No folders found.</p>
              </div>
              <template v-if="!archiveFoldersLoader && archiveFolders.length > 0">
                <div
                  class="item"
                  :class="{ clicked: activeIndex === index }"
                  v-for="(folder, index) in archiveFolders"
                  :key="index"
                  @click="selectItem(folder, index)"
                >
                  <p>{{ folder.name }}</p>
                  <a-dropdown
                    v-if="isLoggedInUser"
                    :trigger="['click']"
                    placement="bottomRight"
                    class="dropdown"
                  >
                    <a class="ant-dropdown-link" @click.stop>
                      <i class="icon icon-vertical_three-dots"></i>
                    </a>
                    <template #overlay>
                      <a-menu>
                        <a-menu-item key="0">
                          <a @click="editFolder(folder)">Edit</a>
                        </a-menu-item>
                        <a-menu-item key="1">
                          <a class="remove-btn" @click="showdeleteFolder(folder)">Remove</a>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </div>
              </template>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
  <folder-modal
    :visible="visibleCategoryFolderModal"
    @handle-close="visibleCategoryFolderModal = false"
    :selectedFolderName="selectedFolderName"
    :selectedFolderId="folderId"
    :loading="loader"
    :isEditMode="isEditing"
    @save-record="handleSave"
  ></folder-modal>
  <message-toaster
    :visible="showMessageModal"
    :message="message"
    @handle-close="showMessageModal = false"
  >
  </message-toaster>
  <delete-modal
    :visible="showFolderDeleteModal"
    @handle-close="showFolderDeleteModal = false"
    title="Are you sure you want to delete this folder?"
    confirmText="Yes, Delete"
    cancelText="No, Don't Delete"
    :loading="loader"
    @delete-record="deleteFolder"
  ></delete-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import archiveMixin from "../../../mixins/archive.mixin";
import FolderModal from "./FolderModal.vue";
import DeleteModal from "../../BaseComponents/DeleteModal.vue";
import MessageToaster from "../../BaseComponents/MessageToaster.vue";

export default {
  mixins: [archiveMixin],
  data() {
    return {
      activeIndex: null,
      activeKey: "public",
      selectedFolderName: "",
      folderId: "",
      visibleCategoryFolderModal: false,
      showFolderDeleteModal: false,
      loader: false,
      isEditing: false,
      showMessageModal: false,
      message: {},
    };
  },
  components: {
    FolderModal,
    DeleteModal,
    MessageToaster
  },
  computed: {
    ...mapGetters({
      archiveFolderList: "archive/archiveFolderList",
      archiveFoldersLoader: "archive/getArchiveFolderLoader",
      isLoggedInUser: 'profile/isLoggedinUser',
      selectedArchiveContentType: 'archive/selectedArchiveContentType',
      selectedUser: 'profile/selectedUser',
      archiveContentCurrentPage: 'archive/archiveContentCurrentPage',
    }),
    archiveFolders() {
      return this.archiveFolderList;
    },
  },
  watch: {
    activeKey(val) {
      if (val === "public")
        this.fetchArchiveFoldersList({ visibility: "public", user_id: this.selectedUser.id });
      else if (val === "private")
        this.fetchArchiveFoldersList({
          visibility: "private",
          user_id: this.selectedUser.id,
        });
    },
    // By default, the sidebar will show the public folders and active the first one
    archiveFolders(folder) {
      if (folder.length > 0) {
        this.activeIndex = 0;
        this.setSelectedFolder(folder[0]);
        if(this.archiveContentCurrentPage > 1){
          //Reset the pagination whenever preselecting the first folder after switching between public and private tabs
          this.updateArchiveContentCurrentPage('move-first-page')  // updating pagination triggers the fetching of articles
        }
        else{
          // if pagination is already set to 1 it will not trigger the watch function of over current page thus fetching the content manaully
          this.getSelectedContentTypeContentList({
            archiveFolderId: folder[0].id,
            contentType: this.selectedArchiveContentType,
            userId: this.selectedUser.id,
          })
        }
      } else {
        this.activeIndex = null;
        this.emptyArchiveContentList()
        this.emptySelectedArchiveFolder()
      }
    },
  },
  created(){
    this.emptyArchiveContentList()
    this.emptySelectedArchiveFolder()
  },
  methods: {
    ...mapActions({
      fetchArchiveFoldersList: "archive/fetchArchiveFoldersList",
      archiveFolderCreate: "archive/archiveFolderCreate",
      saveArticleToArchiveFolder: "archive/saveArticleToArchiveFolder",
      setSelectedFolder: "archive/setSelectedFolder",
      emptyArchiveContentList: 'archive/emptyArchiveContentList',
      emptySelectedArchiveFolder :'archive/emptySelectedArchiveFolder',
      updateArchiveContentCurrentPage: 'archive/updateArchiveContentCurrentPage'
    }),
    selectItem(folder, index) {
      this.activeIndex = index;
      this.setSelectedFolder(folder);
      if(this.archiveContentCurrentPage > 1){
          //Reset the pagination whenever switching between folders
          this.updateArchiveContentCurrentPage('move-first-page')  // updating pagination triggers the fetching of articles
      }
      else{
        this.getSelectedContentTypeContentList({
          archiveFolderId: folder.id,
          contentType: this.selectedArchiveContentType,
          userId: this.selectedUser.id,
        })
      }
    },
    addNewCategory() {
      this.selectedFolderName = "";
      this.folderId = "";
      this.isEditing = false;
      this.visibleCategoryFolderModal = true;
    },
    editFolder(folder) {
      this.selectedFolderName = folder.name;
      this.folderId = folder.id;
      this.isEditing = true;
      this.visibleCategoryFolderModal = true;
    },
    async handleSave(payload) {
      try {
        this.loader = true;
        const requestPayload = {
          name: payload.folderName,
          id: payload.id || null,
        };
        if (this.isEditing) {
          const response = await this.$store.dispatch(
            "archive/updateArchiveFolderName",
            requestPayload
          );
          if (response.status === 200) {
            this.visibleCategoryFolderModal = false;
            this.showMessageModal = true;
            this.message = {
              title: "Category Edit Successfully",
              type: "success",
            };
          }
        } else {
          try {
            this.loader = true;
            const requestPayload = {
              name: payload.folderName,
              visibility: this.activeKey,
            };
            await this.archiveFolderCreate(requestPayload)
            this.visibleCategoryFolderModal = false;
            this.showMessageModal = true;
            this.message = {
              title: "Category Add Successfully",
              type: "success",
            };
          } catch (err) {
            console.log(err);
            this.showMessageModal = true;
            this.message = {
              title: "Category Add Failed",
              type: "failure",
            };
            return;
          }
        }
      } catch (err) {
        console.log(err);
      }
      this.loader = false;
    },
    showdeleteFolder(folder) {
      this.folderId = folder.id;
      this.showFolderDeleteModal = true;
    },
    async deleteFolder() {
      try {
        this.loader = true;
        const response = await this.$store.dispatch(
          "archive/deleteArchiveFolder",
          this.folderId
        );
        if (response.status === 204) {
          this.showFolderDeleteModal = false;
          this.loader = false;
          this.showMessageModal = true;
          this.message = {
            title: "Category Deleted Successfully",
            type: "success",
          };
        }
      } catch (err) {
        console.log(err);
        this.showMessageModal = true;
        this.message = {
          title: "Failed to Delete Category",
          type: "failure",
        };
      }
    },
  },
};
</script>

<style lang="scss">
.archive-management-sidebar {
  height: 100%;
  position: relative;
  margin-bottom: -1px;
  border-right: 1px solid $color-dark-grey-5;
  margin-right: 1.3rem;
  @include respond(phone-x-small) {
    border-right: 0;
  }
  &__header {
    padding: 0.5rem 2.6rem 0.4rem;
    display: flex;
    align-items: center;
    margin-bottom: 4rem;
    &--name {
      display: flex;
      align-items: center;
      width: 100%;
      h3 {
        font-size: 2rem;
        font-family: $font-primary-bold;
        margin-bottom: 0;
        padding: 0;
        line-height: 2.3rem;
        @include respond(tab-port) {
          font-size: 2.2rem;
          line-height: 2.8rem;
        }
      }
      .close-btn {
        display: none;
        margin-left: auto;
        width: fit-content;
        height: fit-content;
        background-color: transparent;
        line-height: normal;
        border: none;
        outline: none;
        cursor: pointer;
        .icon {
          transform: rotate(45deg);
          display: block;
          font-size: 2.5rem;
        }
        @include respond(tab-port) {
          display: block;
        }
      }
    }
  }
  &__body {
    .ant-tabs {
      .ant-tabs-bar {
        border-bottom: 1px solid $color-dark-grey-5;
        margin: 0;
        margin-bottom: 2rem;

        .ant-tabs-nav-container {
          padding: 0 0 0 2.6rem;

          .ant-tabs-tab-prev,
          .ant-tabs-tab-next {
            display: none !important;
          }

          .ant-tabs-nav-wrap {
            .ant-tabs-nav-scroll {
              overflow-x: auto;

              @include respond(phone-x-small) {
                &::-webkit-scrollbar {
                  display: none !important;
                }
              }
              &::-webkit-scrollbar {
                height: 0.5rem;
                width: 0.5rem;
              }

              &::-webkit-scrollbar-track {
                background-color: transparent;
                border-radius: 0.8rem;
                height: 0.5rem;
                width: 0.5rem;
              }

              &::-webkit-scrollbar-thumb {
                background-color: $color-primary;
                border-radius: 0.8rem;
              }

              .ant-tabs-nav {
                width: 100%;
                transform: none !important;
                .ant-tabs-tab {
                  background-color: $color-light-grey-3;
                  padding: 0.8rem 2rem;
                  border-radius: 100px;
                  margin-right: 1.6rem;
                  border-top-right-radius: 1rem !important;
                  border-top-left-radius: 1rem !important;
                  border-bottom-left-radius: 0 !important;
                  border-bottom-right-radius: 0 !important;
                  height: 4.1rem;
                  margin-bottom: -0.1rem;
                  display: flex;
                  align-items: center;
                  .tab-name {
                    display: flex;
                    align-items: center;

                    p {
                      font-size: 1.6rem;
                      line-height: 2;
                      font-family: $font-primary-medium;
                      color: $color-dark-grey-6;
                      margin: 0;
                    }

                    svg {
                      margin-right: 1rem;
                      width: 1.5rem;
                    }

                    svg g path {
                      fill: $color-primary;
                    }
                    .dropdown {
                      margin-left: 0.5rem;
                      .icon {
                        font-size: 1.2rem;
                        color: $color-dark-grey-6;
                      }
                    }
                  }

                  &:last-child {
                    margin-right: 0;
                  }
                }

                .ant-tabs-tab-active {
                  color: $color-white;
                  background-color: $color-primary;
                  font-family: $font-primary-medium;
                  text-shadow: none;

                  .tab-name {
                    p {
                      color: $color-white;
                    }

                    svg g path,
                    svg path {
                      fill: $color-white;
                    }

                    .dropdown {
                      .icon {
                        color: $color-white;
                      }
                    }
                  }
                }

                .ant-tabs-ink-bar {
                  display: none !important;
                }
              }
              .ant-tabs-nav > div {
                display: flex;
              }
            }
          }
        }
      }

      .ant-tabs-top-content {
        .ant-tabs-tabpane {
          height: max-content;
          overflow: hidden;
          padding: 0;
          .add-new-category-btn {
            background-color: $color-light-blue;
            border: none;
            outline: none;
            width: calc(100% - 4.2rem);
            font-size: 1.4rem;
            font-family: $font-primary-medium;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 2rem;
            height: 4rem;
            border-radius: 100px !important;
            cursor: pointer;
            position: sticky;
            margin: 0 auto 3.4rem;
            top: 0;
            .icon {
              color: $color-primary;
              font-size: 1.3rem;
              margin-right: 0.9rem;
            }
          }
          .list {
            margin: 0 2.1rem 4rem 2.6rem;
            position: relative;
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 44rem;
            &::-webkit-scrollbar {
              height: 0.5rem;
              width: 0.5rem;
            }
  
            &::-webkit-scrollbar-track {
              background-color: transparent;
              border-radius: 0.8rem;
              height: 0.5rem;
              width: 0.5rem;
            }
  
            &::-webkit-scrollbar-thumb {
              background-color: $color-primary;
              border-radius: 0.8rem;
            }
            .item {
              width: 100%;
              padding: 2.2rem 2rem;
              border-bottom: 1px solid rgba(135, 149, 157, 0.3);
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: space-between;
              position: relative;
              transition: 0.2s all ease-in-out;
              p {
                font-size: 1.4rem;
                line-height: 1.6rem;
                color: $color-black;
                margin-bottom: 0;
                font-family: $font-primary-regular;
                display: flex;
                align-items: center;
                gap: 1rem;
                @include respond(tab-port) {
                  font-size: 1.8rem;
                  line-height: 2.2rem;
                }
              }
              .dropdown {
                margin-right: -0.5rem;
                .icon {
                  font-size: 1.6rem;
                  color: $color-dark-grey-6;
                }
              }
              &:hover {
                background-color: #ebebeb59;
              }
            }
            .clicked {
              p {
                opacity: 1;
                font-family: $font-primary-medium;
              }
              &::after {
                content: "";
                position: absolute;
                width: 4px;
                height: 100%;
                background-color: $color-primary;
                top: 0;
                right: 0;
              }
            }
            .folder-skeleton {
              margin-bottom: 1.1rem;
              .ant-skeleton-content {
                .ant-skeleton-paragraph {
                  margin: 0;
                  li {
                    height: 5rem;
                  }
                }
              }
            }
            .message {
              padding-top: 4rem;
              p {
                font-size: 1.6rem;
                font-family: $font-primary;
                margin-bottom: 0;
                line-height: 2.3rem;
                color: $color-black;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
</style>
