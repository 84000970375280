<template>
  <div class="profile-page-container">
    <a-row :gutter="[18, 18]">
      <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <TheUserInformation />
        <UserOthersInformation />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import TheUserInformation from "./TheUserInformation.vue";
import UserOthersInformation from "./UserOthersInformation.vue";
export default {
  components: {
    TheUserInformation,
    UserOthersInformation,
  },
};
</script>

<style lang="scss">
.profile-page-container {
  margin-top: 8rem;
  padding: 2rem 4rem 4rem;
  @include respond(phone-x-small) {
    margin-top: 12.8rem;
  }
}
</style>
