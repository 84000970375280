import { mapActions } from 'vuex'
export default {
    data : ()=>({
        // selectedArchiveContentType: "articles",
    }),
    methods:{
        ...mapActions({
            fetchArchiveFoldersContentList: "archive/fetchArchiveFoldersContentList",
            emptyArchiveContentList: 'archive/emptyArchiveContentList',
            toggleArchiveContentLoader: 'archive/toggleArchiveContentLoader',
            toggleArchiveArticleLoader: 'archive/toggleArchiveArticleLoader'
        }),

          async getSelectedContentTypeContentList({archiveFolderId, contentType, userId, emptyPreviousContent=false}){
            if(emptyPreviousContent)
                this.emptyArchiveContentList()
            else {
                contentType === 'articles' ? this.toggleArchiveArticleLoader() : this.toggleArchiveContentLoader()
                await this.fetchArchiveFoldersContentList({ archiveFolderId, contentType, userId});
                contentType === 'articles' ? this.toggleArchiveArticleLoader() : this.toggleArchiveContentLoader()
            }
        }
    }
}