<template>
  <div class="pagination">
    <a-pagination
      show-less-items
      v-model:current="currentPage"
      :total="total"
      :item-render="itemRender"
      :page-size="itemsPerPage"
      @change="onChange"
    />
  </div>
</template>
<script>
export default {
  emits: ["change-page"],
  props: {
    total: Number,
    itemsPerPage: Number
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  mounted(){
    this.emitter.on('search-article', ()=>{
      this.currentPage = 1;
    });
  },
  methods: {
    onChange(page) {
      this.$emit("change-page", page);
    },
    itemRender(current) {
      if (current.type === "prev") {
        return (
            <a class="icon-arrow icon-arrow--preivous" >
              <span class="icon-forward prev" ></span>&ensp;Previous
            </a>
        );
      } else if (current.type === "next") {
        return (
          <a class="icon-arrow icon-arrow--next">
            Next&ensp;<span class="icon-forward"></span>
          </a>
        );
      }
      return current.originalElement;
    },
  },
};
</script>
<style lang="scss">
.pagination {
  padding: 2.4rem !important;
  display: flex;
  align-items: center;
  @include respond(tab-port) {
    padding: 0 !important;
  }
  .ant-pagination {
    margin-left: auto;
    display: flex;
    align-items: baseline;
    &-prev,
    &-next {
      .ant-pagination-item-link {
        margin: 4rem;
        display: none;
        // font-family: $font-secondary-bold;
        // font-size: 1.4rem;
        // border: none;
        // svg {
        //   color: $color-primary;
        // }
      }
      
    }
    .ant-pagination-jump-next{
        display: none;
    }
    .ant-pagination-jump-prev{
        display: none;
    }
    &-item {
      display: none;
      // font-family: $font-secondary-bold;
      // border: none;
      // font-size: 1.6rem;
      // min-width: 22px;
      // background-color: transparent;
      // &:hover a,
      // &:active a,
      // &:focus a {
      //   color: $color-black !important;
      // }
    }
    &-item-active {
      a {
        color: $color-black;
        border-bottom: 3px solid $color-primary;
      }
    }
    &-disabled {
      a {
        span {
          color: #bfbfbf;
        }
      }
    }
  }
  .icon-arrow {
    font-family: $font-secondary-bold !important;
    font-size: 1.8rem;
    padding-right: 2rem;
    span {
      color: $color-primary;  
    }
    .prev {
      display: inline-block;
      transform: rotate(180deg);
    }
  }
}
</style>
