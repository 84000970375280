<template>
    <a-skeleton active :avatar="{ shape: 'square', size: 50 }" :title="{ width: '100%' }"
        :paragraph="{ rows: 2, width: ['100%', '50%'] }" class="profile-skeleton-loader" />
</template>

<style lang="scss">
.profile-skeleton-loader {
    margin: 2rem 0 !important;
    display: flex;
    flex-direction: column;

    .ant-skeleton-header {
        margin-bottom: 2rem;

        .ant-skeleton-avatar {
            width: 20rem !important;
            height: 20rem !important;
            border-radius: 100%;
        }
    }

    .ant-skeleton-content {
        .ant-skeleton-title {
            height: 4rem;
            margin: 0;
        }

        .ant-skeleton-paragraph {
            li {
                height: 2rem;
                margin: 1rem 0;
            }
        }
    }
}
</style>
  