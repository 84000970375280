<template>
  <a-modal
    :visible="visible"
    :footer="null"
    @cancel="closeModal"
    width="70vw"
    class="folder-video-modal"
  >
    <div class="video-modal-container">
      <iframe 
        v-if="isVideoUrl"
        :src="videoUrl"
      >
      </iframe>
      <div v-else class="message">
        <p>Sorry for inconvenience, Unsupported media file.</p>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    videoUrl: String,
  },
  data() {
    return {
      validLinkTypes: [
        'youtube.com',
        'vimeo.com',
        'facebook.com',
        'instagram',
        'tiktok.com',
        'twitter.com',
        'podbean.com',
        'apple.com',
        'Castos.com',
        'Buzzsprout.com',
        'libsyn.com'
      ]
    };
  },
  emits: ["close-modal"],
  computed: {
    isVideoUrl() {
      const abc = this.validLinkTypes.some(type =>
        this.videoUrl.includes(type))
      if (this.validLinkTypes.some(type =>
        this.videoUrl.includes(type))) {
        return true;
      }
        return false;
    },
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");

      // For stop the video in Iframe
      var videos = document.querySelectorAll('iframe, video');
      Array.prototype.forEach.call(videos, function (video) {
        if (video.tagName.toLowerCase() === 'video') {
          video.pause();
        } else {
          var src = video.src;
          video.src = src;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.folder-video-modal {
  top: 8rem;
  .ant-modal-content {
    background-color: transparent;
    height: calc(87vh - 8rem) !important;
    box-shadow: none;

    .ant-modal-close {
      margin: 0;
      .ant-modal-close-x {
        padding: 0;
        width: 4rem;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(0, 0, 0);
        line-height: normal;
        border-start-end-radius: 1rem;
        .anticon {
          color: $color-white;
          font-size: 1.5rem;
          line-height: 1rem;
        }
      }
    }
    .ant-modal-body {
      padding: 0;
      height: 100%;
      .video-modal-container {
        padding: 0;
        width: 100% !important;
        height: 100%;
        border-radius: 1rem;
        .message {
          width: 100%;
          height: 100%;
          background-color: $color-white;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 1rem;
          padding: 2rem;
          p {
            font-size: 2rem;
            margin-bottom: 0;
            font-family: $font-primary;
            text-align: center;
          }
        }
        iframe {
          width: 100%;
          height: 100%;
          border-radius: 1rem;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
          border: none;
          background-color: $color-white;
        }
      }
    }
  }
}
</style>
