<template>
  <div class="language-section">
    <div class="language-section__header">
      <h2 class="language-section__header--title">Languages</h2>
      <button class="language-section__header--btn" @click="addLanguage" v-if="isLoggedInUser" id="languageAddBtn" title="languageAddBtn">
        <i class="icon icon-add_plus"></i>
      </button>
    </div>
    <div class="language-section__body">
      <a-row v-if="isLangaugesLoading" :gutter="[24, 24]">
        <a-col
          :xs="24"
          :sm="12"
          :md="6"
          :lg="6"
          :xl="6"
          v-for="languageSkeleton in 4"
          :key="languageSkeleton"
        >
          <languages-skeleton></languages-skeleton>
        </a-col>
      </a-row>

      <a-row v-else :gutter="[24, 24]">
        <a-col
          :xs="24"
          :sm="12"
          :md="8"
          :lg="6"
          :xl="5"
          v-for="language in languages"
          :key="language"
        >
          <div class="language">
            <div class="language__image">
              <img
                :src="language.language.flag"
                alt="Flag"
                class="language__image--flag"
              />
            </div>
            <div class="language__details">
              <h3
                class="language__details--title language__details--limit-text"
              >
                {{ language.language.language }}
              </h3>
              <p class="language__details--para language__details--limit-text">
                {{ language.proficiency }}
              </p>
            </div>
            <a-dropdown :trigger="['click']" class="language__dropdown" v-if="isLoggedInUser">
              <a class="ant-dropdown-link" @click.prevent>
                <i class="icon icon-vertical_three-dots"></i>
              </a>
              <template #overlay>
                <a-menu>
                  <a-menu-item key="0">
                    <a @click="editLanguage(language.language, language.proficiency, language.id)">Edit</a>
                  </a-menu-item>
                  <a-menu-item key="1">
                    <a @click="showDeleteModal(language.id)" class="remove-btn">Delete</a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </a-col>
      </a-row>
      <p v-if="!isLangaugesLoading && languages.length == 0" class="message">
        No languages added
      </p>
    </div>
  </div>
  <add-language-modal
    ref="language-modal"
    :visible="showLanguageModal"
    :action="action"
    @close-modal="()=>{showLanguageModal = false}"
    :footer="null"
  ></add-language-modal>
  <delete-modal
    :visible="deleteModalVisible"
    @handle-close="deleteModalVisible = false"
    title="Are you sure you want to delete this record?"
    confirmText="Yes, Delete"
    cancelText="No, Don't Delete"
    message="The record will be permanently deleted."
    @delete-record="deleteLanguage()"
    :loading="deleteloading"
  ></delete-modal>
  <message-toaster
    :visible="showMessageModal"
    :message="message"
    @handle-close="showMessageModal = false"
  >
  </message-toaster>
</template>

<script>
import LanguagesSkeleton from "./SkeletonLoaders/ConnectionSkeletonLoader.vue";
import DeleteModal from "../BaseComponents/DeleteModal.vue";
import MessageToaster from "../BaseComponents/MessageToaster.vue";
import AddLanguageModal from "../ProfilePageComponents/AddLanguageModal.vue";

export default {
  data() {
    return {
      showLanguageModal: false,
      deleteloading: false,
      deleteModalVisible: false,
      showMessageModal: false,
      action: "",
      message: {},
    };
  },
  components: {
    LanguagesSkeleton,
    DeleteModal,
    MessageToaster,
    AddLanguageModal,
  },
  computed: {
    isLangaugesLoading() {
      return this.$store.getters["profile/getLanguagesSkeletonLoader"];
    },
    languages() {
      return this.$store.getters["profile/getLanguages"];
    },
    isLoggedInUser() {
      return this.$store.getters["profile/isLoggedinUser"];
    },
  },
  methods: {
    async getLanguage() {
      this.skeletonLoading = true;
      try {
        const payload = {
          user_id: this.$route.params.id,
        };
        await this.$store.dispatch("profile/getAddedLanguages", payload);
      } catch (err) {
        console.log(err);
      }
      this.skeletonLoading = false;
    },
    addLanguage() {
      this.action = "add";
      this.showLanguageModal = true;
    },
    editLanguage(language, proficiency, id) {
      this.action = "edit";
      this.showLanguageModal = true;
      language.proficiency = proficiency
      this.$refs["language-modal"].selectLanguage(language, id);
    },
    async deleteLanguage() {
      try {
        this.deleteloading = true;
        this.$store
          .dispatch("profile/deleteLanguage", this.selectedRecordId)
          .then(() => {
            this.message = {
              title: "Deleted Successfully",
              desc: "",
              type: "success",
            };
            this.showMessageModal = true;
            this.selectedRecordId = null;
            this.deleteModalVisible = false;
            this.deleteloading = false;
          });
      } catch (err) {
        this.message = {
          title: "Something went wrong",
          desc: "Please Try Again later. Sorry for the inconvenience",
          type: "failure",
        };
        this.showMessageModal = true;
        this.selectedRecordId = null;
        this.deleteloading = false;
        this.deleteModalVisible = false;
      }
      
    },
    showDeleteModal(id) {
      this.selectedRecordId = id;
      this.deleteModalVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.language-section {
  padding: 3rem 2.6rem;
  border-top: 1px solid $color-dark-grey-5;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &--title {
      font-size: 2rem;
      font-family: $font-primary-medium;
      margin-bottom: 0;
      line-height: 2.3rem;
      color: $color-black;
      @include respond(tab-port) {
        font-size: 2.2rem;
        line-height: 2.8rem;
      }
    }
    &--btn {
      background-color: $color-primary;
      height: 4.2rem;
      width: 4.2rem;
      border: none;
      outline: none;
      border-radius: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .icon {
        color: $color-white;
        font-size: 1.6rem;
      }
    }
  }
  &__body {
    margin-top: 2.6rem;
    .language {
      display: flex;
      align-items: center;
      padding: 0.6rem;
      border-radius: 100px;
      width: fit-content;
      background-color: #e1e1e1;
      width: 100%;
      @include respond(tab-port) {
        padding: 1rem;
      }
      &__image {
        width: 4rem;
        height: 4rem;
        margin-right: 1.2rem;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &--flag {
          width: 4rem;
          height: 4rem;
          border-radius: 100%;
          background-color: rgba(128, 128, 128, 0.244);
          object-fit: contain;
        }
        @include respond(tab-port) {
          width: 5rem;
          height: 5rem;
          &--flag {
            width: 5rem;
            height: 5rem;
          }
        }
      }
      &__details {
        width: 60%;
        &--title {
          font-size: 1.8rem;
          font-family: $font-primary-medium;
          margin-bottom: 0.4rem;
          line-height: 2.2rem;
          color: $color-black;
          @include respond(tab-port) {
            font-size: 2rem;
            line-height: 2.2rem;
          }
        }
        &--para {
          font-size: 1.2rem;
          font-family: $font-primary;
          margin-bottom: 0;
          line-height: 1.4rem;
          color: #7b7b7b;
          @include respond(tab-port) {
            font-size: 1.6rem;
            line-height: 2rem;
          }
        }
        &--limit-text {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2;
          -webkit-box-orient: vertical;
          min-width: 4rem;
        }
      }
      &__dropdown {
        margin-left: auto;
        margin-right: 1rem;
        .icon {
          color: $color-black;
          font-size: 1.8rem;
          @include respond(tab-port) {
            font-size: 2rem;
          }
        }
      }
    }
    .message {
      font-size: 1.6rem;
      font-family: $font-primary;
      margin-bottom: 0;
      line-height: 2.3rem;
      color: $color-black;
      @include respond(tab-port) {
        font-size: 1.8rem;
        line-height: 2.2rem;
      }
    }
  }
}
</style>
