<template>
  <div v-if="loading" class="accuracy-credibility-container-skeleton">
    <a-skeleton
      active
      :title="{ width: '20%' }"
      :paragraph="{ rows: 2, width: ['70%', '100%'] }"
    />
  </div>
  <a-row v-else :gutter="[24]">
    <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="isInlineRatingColumn">
      <div class="accuracy-credibility-container">
        <div class="accuracy-credibility-container__title">Accuracy</div>
        <div
          v-for="choice in accuracyRatings.choices"
          :key="choice"
          class="accuracy-credibility-container__results-container"
        >
          <div class="result">
            <div
              :class="{
                'circle green': choice.choice === 'Accurate',
                'circle yellow': choice.choice === 'Somewhat Accurate',
                'circle red': choice.choice === 'Lacks Accuracy',
              }"
            ></div>
            <h3>{{ choice.choice }}</h3>
            <div class="images">
              <img
                v-for="image in choice.users"
                :key="image"
                :src="image.profile.profile_picture"
                alt="image"
              />
              <div v-if="choice.total_users === 0" class="default-image"></div>
            </div>
            <h5>
              <span>{{
                choice.total_users > 300 ? "300+" : choice.total_users
              }}</span>
            </h5>
          </div>
        </div>
      </div>
    </a-col>
    <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="isInlineRatingColumn">
      <div class="accuracy-credibility-container">
        <div class="accuracy-credibility-container__title">Credibility</div>
        <div
          v-for="choice in credibilityRatings.choices"
          :key="choice"
          class="accuracy-credibility-container__results-container"
        >
          <div class="result">
            <div
              :class="{
                'circle green': choice.choice === 'Credible',
                'circle yellow': choice.choice === 'Somewhat Credible',
                'circle red': choice.choice === 'Lacks Credibility',
              }"
            ></div>
            <h3>{{ choice.choice }}</h3>
            <div class="images">
              <img
                v-for="image in choice.users"
                :key="image"
                :src="image.profile.profile_picture"
                alt="image"
              />
              <div v-if="choice.total_users === 0" class="default-image"></div>
            </div>
            <h5>
              <span>{{
                choice.total_users > 300 ? "300+" : choice.total_users
              }}</span>
            </h5>
          </div>
        </div>
      </div>
    </a-col>
  </a-row>
  <button v-if="!loading" @click="visibleJustificationsModal" class="see-justifaction-btn">See Justifactions</button>

  <!-- See all Justifactions Modal -->
  <a-modal
    v-model:visible="showJustificationsModal"
    :footer="null"
    wrapClassName="see-justifications-modal"
  >
    <all-justifications 
      :visibleModal="showJustificationsModal" 
      :articleId="articleId"
      :accuracyId="accuracyRatings"
      :credibilityId="credibilityRatings"
    ></all-justifications>
  </a-modal>

  <!-- See all Justifactions Drawer -->
  <a-drawer
    placement="bottom"
    :visible="showJustificationsDrawer"
    class="see-justifications-drawer"
  >
    <all-justifications 
      :visibleModal="showJustificationsDrawer" 
      :articleId="articleId"
    ></all-justifications>
  </a-drawer>
</template>

<script>
import AllJustifications from './AllJustifications.vue';

export default {
  props: {
    loading: Boolean,
    isInlineRatingColumn: Number,
    accuracyRatings: Object,
    credibilityRatings: Object,
    articleId: String
  },
  components: {
    AllJustifications
  },

  data(){
    return {
      showJustificationsModal: false,
      showJustificationsDrawer: false
    }
  },
  computed: {
    accuracyRatingsLength() {
      
      if (Object.keys(this.accuracyRatings).length > 1) return true;
      return false;
    },
    credibilityRatingsLength() {
      if (Object.keys(this.credibilityRatings).length > 1) return true;
      return false;
    },
  },
  methods: {
    visibleJustificationsModal(){
      this.showJustificationsModal = true;
    },
    visibleJustificationsDrawer(){
      this.showJustificationsDrawer = true;
    }
  }
};
</script>


<style lang="scss">
.accuracy-credibility-container {
  &__title {
    font-size: 1.6rem;
    font-family: $font-primary-medium;
    line-height: 2.4rem;
    color: $color-black;
  }
  &__results-container {
    .result {
      display: flex;
      align-items: center;
      margin: 2rem 0;
      .circle {
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 100%;
        margin-right: 0.7rem;
      }
      .green {
        background-color: rgba(16, 196, 13, 0.678);
        border: 1px solid rgba(16, 196, 13, 1);
      }
      .yellow {
        background-color: #ffd900;
        border: 1px solid #bfa512;
      }
      .red {
        background-color: #ec4242;
        border: 1px solid #a42525;
      }
      h3 {
        font-size: 1.3rem;
        font-family: $font-primary;
        line-height: 1.6rem;
        color: $color-black;
        margin-bottom: 0;
        margin-right: 2rem;
        color: rgba(67, 67, 67, 1);
      }
      .images {
        max-width: 10.3rem;
        display: flex;
        align-items: center;
        img {
          width: 2.6rem;
          height: 2.6rem;
          object-fit: cover;
          border-radius: 100%;
          border: 2px solid $color-white;
          margin-left: -1.2rem;
        }
        .default-image {
          width: 2rem;
          height: 2rem;
          object-fit: cover;
          margin-left: -1.2rem;
          background-color: rgba(217, 217, 217, 1);
          border-radius: 100%;
        }
      }
      h5 {
        font-size: 1.3rem;
        font-family: $font-primary-medium;
        line-height: 1.8rem;
        font-weight: 500;
        color: $color-black;
        margin-bottom: 0;
        margin-left: 0.4rem;
      }
    }
  }
}
.see-justifaction-btn {
  width: 100%;
  background-color: $color-primary;
  font-size: 1.4rem;
  font-family: $font-primary-medium;
  color: $color-white;
  height: 3.6rem;
  border: none;
  outline: none;
  border-radius: 0.5rem;
  margin-top: 0.4rem;
  cursor: pointer;
}
.see-justifications-modal {
  .ant-modal {
    width: 52rem !important;
    .ant-modal-content {
      .ant-modal-close {
        margin: 3.5rem 2.2rem 0 0;
        .ant-modal-close-x {
          width: fit-content;
          height: fit-content;
          line-height: 0;
          color: $color-black;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
.see-justifications-drawer {
  .ant-drawer-content-wrapper {
    height: auto !important;
    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        overflow: hidden;
        height: 100%;
        .ant-drawer-header-no-title {
          .ant-drawer-close {
            width: fit-content;
            height: fit-content;
            line-height: normal;
            margin: 3.8rem 2.5rem;
            color: $color-black;
            .anticon {
              font-size: 2.2rem;
              color: $color-black;
            }
          }
        }
        .ant-drawer-body {
          padding: 0;
        }
      }
    }
  }
}
</style>

