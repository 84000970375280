<template>
  <div class="share-article-modal">
    <div class="share-article-modal__header">
      <h1>Share Article</h1>
    </div>
    <div class="share-article-modal__body">
       <a-tabs @change="modalActiveTab" v-model:activeTab="activeTab">
        <a-tab-pane key="activeFeedTab" tab="Share on your feed">
          <div class="share-card">
            <div class="share-card__image">
              <img :src="image" alt="" class="image" />
            </div>
            <div class="share-card__source">
              <h3>{{ source }}</h3>
            </div>
            <div class="share-card__author">
              <p>Published Date:</p>
              <h4>{{ date }}</h4>
            </div>
            <div class="share-card__title">
              <h2>
                {{ title }}
              </h2>
            </div>
            <a-button class="share-card__btn" @click="shareFeed(id)" :class="{ 'disable-btn': onSharePendReq }"
              :loading="onSharePendReq">
              Share
            </a-button>
          </div>
        </a-tab-pane>
        <a-tab-pane key="activePortfolioTab" tab="Share on your portfolio">
          <div class="share-card">
            <div class="share-card__image">
              <img :src="image" alt="" class="image" />
            </div>
            <div class="share-card__source">
              <h3>{{ source }}</h3>
            </div>
            <div class="share-card__author">
              <p>Published Date:</p>
              <h4>{{ date }}</h4>
            </div>
            <div class="share-card__title">
              <h2>
                {{ title }}
              </h2>
            </div>
            <a-button class="share-card__btn" @click="shareArticle" :class="{ 'disable-btn': onSharePendReq }"
              :loading="onSharePendReq">
              Share
            </a-button>
          </div>
        </a-tab-pane>
        <a-tab-pane v-if="!isImpact" key="activeContactTab" tab="Share with your network" force-render>
          <div class="share-with-connections share-card" >
            <div class="share-card__image">
              <img :src="image" alt="" class="image" />
            </div>
            <div class="share-card__source">
              <h3>{{ source }}</h3>
            </div>
            <div class="share-card__author">
              <p>Published Date:</p>
              <h4>{{ date }}</h4>
            </div>
            <div class="share-card__title">
              <h2>
                {{ title }}
              </h2>
            </div>
            <div class="share-with-connections__category">
              <p>Select a connection</p>
              <tagify-contacts placeholder="Share with your network" :options="contacts" @fetchSelection="fetchedSelection"
                :isSubmitted="isSubmitted" :id="id+1"></tagify-contacts>
              <!-- <a-select
                v-model:value="selectedCategory"
                suffixIcon
                placeholder="Select a Category"
                style="width: 100%"
                :options="options"
              >
                <template #option="{ value: val, label, icon }">
                  <img class="options-select-img" :src="icon" alt="" />
                  <span :aria-label="val" class="options-select-name">{{
                    label
                  }}</span>
                </template>
              </a-select> -->
              <p v-if="selectBoxErr" class="error">{{ selectBoxErr }}</p>
              <p>Message</p>
              <textarea name="" id="" rows="2" required v-model="shareMessage"></textarea>
              <p v-if="messageBoxErr" class="error">{{ messageBoxErr }}</p>
            </div>
            <a-button :loading="onSharePendReq" class="share-with-connections__btn"
              @click="shareToConnection()">Share</a-button>
          </div>
        </a-tab-pane>
        <a-tab-pane  v-if="!isImpact"  key="activeEmailTab" tab="Share via email" force-render>
          <div class="share-with-connections share-card">
            <div class="share-card__image">
              <img :src="image" alt="" class="image" />
            </div>
            <div class="share-card__source">
              <h3>{{ source }}</h3>
            </div>
            <div class="share-card__author">
              <p>Published Date:</p>
              <h4>{{ date }}</h4>
            </div>
            <div class="share-card__title">
              <h2>
                {{ title }}
              </h2>
            </div>
            <div class="share-with-connections__category">
              <p>Enter Email</p>
              <tagify-email placeholder="Invite Via Email" @fetchEmail="fetchedEmail"
                :isSubmitted="isSubmitted"></tagify-email>
              <!-- <input type="text" v-model="selectedEmail" /> -->
              <p v-if="selectBoxErr" class="error">{{ selectBoxErr }}</p>
              <p>Message</p>
              <textarea name="" id="" rows="2" required v-model="shareMessage"></textarea>
              <p v-if="messageBoxErr" class="error">{{ messageBoxErr }}</p>
            </div>
            <a-button :loading="onSharePendReq" class="share-with-connections__btn"
              @click="shareOnEmail()">Share</a-button>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import TagifyContacts from "../PdbComponents/TagifyContacts.vue";
import TagifyEmail from "../PdbComponents/TagifyEmail.vue";

export default {
  components: {
    TagifyContacts,
    TagifyEmail,
  },
  props: {
    title: String,
    image: String,
    source: String,
    date: String,
    id: String,
    url: String,
    isImpact: Boolean
  },
  computed: {
    contacts() {
      const value = this.$store.getters["profile/getFriends"];
      value.map((val) => {
        if (val) {
          let userList = {
            label: val.full_name,
            value: val.id,
            key: val.email,
            icon: val.profile_picture,
          };
          this.friends.push(userList);
          return userList;
        }
      });
      return this.friends;
    },
    isAuthenticatedUser() {
      return this.$store.getters['auth/isAuthenticated'];
    }
  },
  emits: ["close-article-share-modal"],
  data() {
    return {
      articleShareModal: false,
      activeTab: "activePortfolioTab",
      onSharePendReq: false,
      options: [],
      selectBoxErr: "",
      messageBoxErr: "",
      shareMessage: "",
      // selectedCategory: "",
      selectedConnections: [],
      // selectedEmail: "",
      isSubmit: false,
      selectedEmail: [],
      friends: [],
      isSubmitted:false,
    };
  },
  methods: {
    ...mapActions("feed", ["createFeed",]),
    async shareArticle() {
      this.onSharePendReq = true;
      try {
        const payload = this.isImpact ? { impact_story: this.id } : { article: this.id };
        const actionType = this.isImpact ? "impact/shareArticle" : "article/shareArticle";
        const response = await this.$store.dispatch(actionType, payload);
        if (response.status === 201) {
          this.onSharePendReq = false;
          const msg = {
            modalVisibility: false,
            showMessageModal: true,
            title: 'Article shared successfully',
            type: 'success'
          }
          this.emitter.emit("close-article-share-modal", msg);
        }
      } catch (err) {
        this.onSharePendReq = false;
         const msg = {
          modalVisibility: false,
          showMessageModal: true,
          title: err.response.data[0],
          type: 'failure'
        }
        this.emitter.emit("close-article-share-modal", msg);  
      }
    },
    async shareToConnection() {
      if (this.selectedConnections.length === 0) {
        this.selectBoxErr = "This field can not be empty";
        return true;
      }
      if (this.shareMessage == "") {
        this.messageBoxErr = "This field can not be empty";
        return true;
      }
      try {
        this.onSharePendReq = true;
        let connectionList = this.selectedConnections.map((val) => val.key );
        const payload = connectionList.map(email => ({
          email,
          message: this.shareMessage,
          link: this.url,
          article_id: this.id
        }));
        const response = await this.$store.dispatch(
          "article/shareArticleWithConnections",
          payload
        );
        if (response.status == 201) {
          this.shareMessage = "";
          const msg = {
            modalVisibility: false,
            showMessageModal: true,
            title: 'Article shared successfully',
            type: 'success'
          }
          this.emitter.emit("close-article-share-modal", msg);  
          this.onSharePendReq = false;
          this.selectedConnections = [];
          this.selectBoxErr = "";
          this.messageBoxErr = "";
          this.isSubmitted=await true
          this.isSubmitted=await false
        }
      } catch (err) {
        this.onSharePendReq = false;
        const msg = {
          modalVisibility: false,
          showMessageModal: true,
          title: err.response.data[0],
          type: 'failure'
        }
        this.emitter.emit("close-article-share-modal", msg);  
      }
    },
    async shareOnEmail() {
      if (this.selectedEmail.length === 0) {
        this.selectBoxErr = "This field can not be empty";
        return true;
      }
      if (this.shareMessage == "") {
        this.messageBoxErr = "This field can not be empty";
        return true;
      }
      try {
        this.onSharePendReq = true;
        const payload = this.selectedEmail.map(email => ({
          email,
          message: this.shareMessage,
          link: `https://www.staging.glocal.com/discover/?article=${this.id}`,
          article_id: this.id
        }));
          
        const response = await this.$store.dispatch(
          "article/shareArticleWithConnections",
          payload
        );
        if (response.status == 201) {
          this.shareMessage = "",
          this.onSharePendReq = false;
          const msg = {
            modalVisibility: false,
            showMessageModal: true,
            title: 'Article shared successfully',
            type: 'success'
          }
          this.emitter.emit("close-article-share-modal", msg); 
          this.selectedEmail = [];
          this.selectBoxErr = "";
          this.messageBoxErr = "";
          this.isSubmitted=await true
          this.isSubmitted=await false
        }
      } catch (err) {
        const error = err.response.data[0];
        this.showMessageModal = true;
        this.onSharePendReq = false;
        const msg = {
          articleShareModal: false,
          showMessageModal: true,
          type: error
        }
        this.emitter.emit("close-article-share-modal", msg); 
      }
    },
    async shareFeed(articleId) {
      this.onSharePendReq = true;
      const payload = {
        article: articleId,
        content_type: 'article'
      };
      this.createFeed(payload)
      .then((response) => {
          if (response.status === 201) {
          this.onSharePendReq = false;
          const msg = {
            modalVisibility: false,
            showMessageModal: true,
            title: 'Article shared successfully',
            type: 'success'
          }
          this.emitter.emit("close-article-share-modal", msg);
        }
      })
      .catch((error) => {
        this.onSharePendReq = false;
        const msg = {
          modalVisibility: false,
          showMessageModal: true,
          title: error.response.data[0],
          type: 'failure'
        }
        this.emitter.emit("close-article-share-modal", msg);  
      });
    },
    fetchedSelection(val) {
      this.selectedConnections = val;
    },
    fetchedEmail(val) {
      this.selectedEmail = val;
      // this.selectedEmail = val;
    },
    modalActiveTab(val){
      this.emitter.emit('activeTabs', val);
    }
  },
};
</script>

<style lang="scss">
.share-article-modal {
  padding: 2rem;

  &__header {
    // padding: 2rem;
    margin-bottom: 1rem;

    h1 {
      font-size: 2.6rem;
      font-family: $font-primary-bold;
      color: $color-black;
      margin-bottom: 0;
      line-height: normal;
    }
  }

  &__body {
    .ant-tabs {
      .ant-tabs-bar {
        border-bottom: none;
        margin-bottom: 2rem;

        .ant-tabs-nav-container {
          .ant-tabs-nav-wrap {
            .ant-tabs-nav-scroll {
              .ant-tabs-nav {
                width: 100%;

                // padding: 0 2rem;
                .ant-tabs-tab {
                  margin-right: 1rem;
                  padding: 0;
                  margin-right: 2rem;
                  font-size: 1.6rem;
                  font-family: $font-primary;
                  color: $color-black;
                  opacity: 0.6;
                  position: relative;
                  padding: 1rem 0;
                  cursor: pointer;
                }
                .ant-tabs-tab:last-child {
                  margin-right: 0;
                }

                .ant-tabs-tab-active {
                  color: $color-black;
                  text-shadow: none;
                  border-bottom: none !important;
                  opacity: 1;

                  &::after {
                    content: "";
                    width: 7rem;
                    height: 0.4rem;
                    background-color: #0033dd;
                    position: absolute;
                    bottom: -0.5rem;
                    left: 0;
                    opacity: 1;
                    top: 3.8rem;
                  }
                }

                .ant-tabs-ink-bar {
                  display: none;
                  background-color: transparent;
                }
              }
            }
          }
        }
      }

      .ant-tabs-top-content {
        .ant-tabs-tabpane {
          .share-card {
            &__image {
              width: 100%;
              height: 100%;
              max-height: 38rem;
              margin-bottom: 2rem;

              img {
                width: 100%;
                height: 100%;
                max-height: 38rem;
                object-fit: cover;
              }
            }

            &__source {
              margin-bottom: 0.3rem;

              h3 {
                font-size: 1.6rem;
                font-family: "Roboto-bold";
                color: $color-primary;
                text-transform: uppercase;
                margin-bottom: 0;
                font-family: $font-primary-bold;
              }
            }

            &__author {
              display: flex;
              align-items: center;

              p {
                margin-right: 1rem;
                font-size: 1.4rem;
                margin-bottom: 0rem;
                opacity: 0.7;
              }

              h4 {
                font-size: 1.3rem;
                font-family: $font-primary-bold;
                margin-bottom: 0;
              }
            }

            &__title {
              padding: 1rem 0;
              border-bottom: 0.18rem solid rgba(128, 128, 128, 0.123);

              h2 {
                font-family: $font-primary-bold;
                color: $color-black;
                width: 100%;
                font-size: 1.8rem;
                line-height: 2.2rem;
                margin-bottom: 0;
              }
            }

            &__btn {
              border: none;
              font-size: 1.6rem;
              font-family: $font-primary-medium;
              width: 100%;
              height: 4rem;
              cursor: pointer;
              margin-top: 2rem;
              background-color: $color-primary;
              color: $color-white;
              border-radius: 0;
            }
          }

          .share-with-connections {
            &__title {
              font-family: $font-primary-bold;
              color: $color-black;
              width: 100%;
              font-size: 2.2rem;
              line-height: 2rem;
              // margin-bottom: 1rem;
              text-align: center;
            }

            &__category {
              input {
                font-size: 1.6rem;
                font-family: $font-primary;
                outline: none;
                border: 1px solid rgba(1, 30, 41, 0.1);
                width: 100%;
                padding: 0.5rem 1rem;
              }

              .tagify {
                &__input {
                  &::before {
                    max-width: 87%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
              }

              p {
                font-family: $font-primary;
                color: $color-black;
                width: 100%;
                font-size: 1.6rem;
                margin: 1.5rem 0 0.5rem;
                opacity: 0.6;
              }
            }

            textarea {
              width: 100%;
              resize: none;
              outline: none;
              border: 1px solid rgba(0, 0, 0, 0.2);
              margin-top: 0.1rem;
              padding: 1rem;
              font-size: 1.44rem;
              font-family: $font-secondary;
            }

            &__btn {
              border: none;
              font-size: 1.6rem;
              font-family: $font-primary-medium;
              width: 100%;
              height: 4rem;
              cursor: pointer;
              margin-top: 2rem;
              background-color: $color-primary;
              color: $color-white;
              border-radius: 0;
            }

            .error {
              color: rgb(255, 0, 0);
              margin: 0.5rem 0 1rem;
            }
          }
        }
      }
    }
  }
}

.options-select-img {
  height: 3rem;
  width: 3rem;
}

.options-select-name {
  font-size: 1.6rem;
  margin-left: 1rem;
}
</style>
