<template>
  <a-modal
    :visible="visible"
    :footer="null"
    @cancel="closeModal"
    class="share-qr-code-modal"
  >
    <div v-if="user" class="share-qr-code-modal-container">
      <div class="share-qr-code-modal-container__header">
        <img :src="user.profile_picture" alt="" />
      </div>
      <div class="share-qr-code-modal-container__body">
        <h3>{{ user.full_name }}</h3>
        <p>Scan the QR Code below to find me on glocal</p>
        <div class="qr-code">
          <img :src="QrCodeUrl" />
        </div>
      </div>
      <div class="share-qr-code-modal-container__footer">
        <button @click="copyProfileLink()" class="button">
          <i class="icon-copy icon"></i>
          Copy Link
          <div class="copied" v-if="copiedLink">
            <p>Copied!</p>
          </div>
        </button>
        <button @click="downloadQR" class="button primary-btn">
          <svg
            width="12"
            height="13"
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 9.55234C5.9 9.55234 5.80625 9.53672 5.71875 9.50547C5.63125 9.47422 5.55 9.42109 5.475 9.34609L2.775 6.64609C2.625 6.49609 2.55313 6.32109 2.55938 6.12109C2.56563 5.92109 2.6375 5.74609 2.775 5.59609C2.925 5.44609 3.10312 5.36797 3.30937 5.36172C3.51562 5.35547 3.69375 5.42734 3.84375 5.57734L5.25 6.98359V1.62109C5.25 1.40859 5.32188 1.23047 5.46562 1.08672C5.60938 0.942969 5.7875 0.871094 6 0.871094C6.2125 0.871094 6.39063 0.942969 6.53438 1.08672C6.67813 1.23047 6.75 1.40859 6.75 1.62109V6.98359L8.15625 5.57734C8.30625 5.42734 8.48438 5.35547 8.69063 5.36172C8.89688 5.36797 9.075 5.44609 9.225 5.59609C9.3625 5.74609 9.43438 5.92109 9.44063 6.12109C9.44688 6.32109 9.375 6.49609 9.225 6.64609L6.525 9.34609C6.45 9.42109 6.36875 9.47422 6.28125 9.50547C6.19375 9.53672 6.1 9.55234 6 9.55234ZM1.5 12.8711C1.0875 12.8711 0.734375 12.7242 0.440625 12.4305C0.146875 12.1367 0 11.7836 0 11.3711V9.87109C0 9.65859 0.071875 9.48047 0.215625 9.33672C0.359375 9.19297 0.5375 9.12109 0.75 9.12109C0.9625 9.12109 1.14062 9.19297 1.28437 9.33672C1.42813 9.48047 1.5 9.65859 1.5 9.87109V11.3711H10.5V9.87109C10.5 9.65859 10.5719 9.48047 10.7156 9.33672C10.8594 9.19297 11.0375 9.12109 11.25 9.12109C11.4625 9.12109 11.6406 9.19297 11.7844 9.33672C11.9281 9.48047 12 9.65859 12 9.87109V11.3711C12 11.7836 11.8531 12.1367 11.5594 12.4305C11.2656 12.7242 10.9125 12.8711 10.5 12.8711H1.5Z"
              fill="white"
            />
          </svg>
          Download QR
        </button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import QRCode from "qrcode";

export default {
  data() {
    return {
      QrCodeUrl: "",
      copiedLink: false,
    };
  },
  props: {
    visible: Boolean,
  },
  watch: {
    user: {
      handler(val) {
        if (val) {
          const profileUrl = `${process.env.VUE_APP_REDIRECTION_URL}/user/${this.userId}?tab=experience`;
          QRCode.toDataURL(profileUrl)
            .then((url) => {
              this.QrCodeUrl = url;
            })
            .catch((err) => {
              console.error(err);
            });
        }
      },
      immediate: true,
    },
  },
  computed: {
    user() {
      return this.$store.getters["profile/getProfileInfo"];
    },
    userId() {
      return this.$store.getters["auth/getUserId"];
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
      this.copiedLink = false;
    },
    async downloadQR() {
      try {
        const link = document.createElement("a");
        link.href = this.QrCodeUrl;
        link.download = "qr_code.png";

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
      } catch (error) {
        console.error("Error generating QR code:", error);
      }
    },
    copyProfileLink() {
      this.copiedLink = true;
      setTimeout(() => {
        this.copiedLink = false;
      }, 2000);
      const profileUrl = `${process.env.VUE_APP_REDIRECTION_URL}/user/${this.userId}?tab=experience`;
      navigator.clipboard.writeText(profileUrl);
    },
  },
};
</script>

<style lang="scss">
.share-qr-code-modal {
  width: 36rem !important;
  top: 15rem;
  .ant-modal-content {
    background-color: $color-white;
    position: relative;
    height: 48rem;
    .ant-modal-body {
      .share-qr-code-modal-container {
        position: absolute;
        top: -7rem;
        right: 50%;
        transform: translateX(50%);
        width: 100%;
        &__header {
          width: 12rem;
          height: 12rem;
          border-radius: 100%;
          margin: 0 auto 1rem;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            border: 5px solid $color-white;
            background-color: $color-white;
          }
        }
        &__body {
          h3 {
            color: $color-black;
            font-size: 2.2rem;
            font-family: $font-primary-medium;
            margin-bottom: 0;
            line-height: 2.6rem;
            margin-bottom: 0;
            text-align: center;
          }
          p {
            color: $color-black;
            font-size: 1.4rem;
            font-family: $font-primary;
            margin-bottom: 0;
            line-height: 1.8rem;
            margin-bottom: 2rem;
            text-align: center;
            opacity: 0.7;
          }
          .qr-code {
            height: 25rem;
            padding: 3rem;
            margin: 0 3rem 3rem;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            border-radius: 1rem;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        &__footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 3rem;
          gap: 3rem;
          .button {
            border-radius: 100px !important;
            background-color: $color-white;
            width: 50%;
            height: 4.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $color-black;
            font-size: 1.2rem;
            font-family: $font-primary;
            line-height: 1.6rem;
            margin: 0;
            border: 1px solid $color-black;
            outline: none;
            cursor: pointer;
            position: relative;
            svg {
              margin-right: 1rem;
              width: 1.3rem;
              height: auto;
              margin-bottom: 0.2rem;
            }
            .icon {
              margin-right: 0.8rem;
              font-size: 1.3rem;
              color: $color-black;
            }
            .copied {
              position: absolute;
              box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
              bottom: -3rem;
              padding: 0.5rem 1rem;
              border-radius: 0.5rem;
              p {
                font-size: 1.2rem;
                font-family: $font-primary;
                margin-bottom: 0;
                line-height: 1.6rem;
              }
            }
          }
          .primary-btn {
            background-color: $color-primary;
            color: $color-white;
            font-family: $font-primary-medium;
            font-weight: 500;
            border-color: $color-primary;
            svg {
              margin-right: 0.5rem;
              width: 1.2rem;
              height: auto;
            }
          }
        }
      }
    }
  }
}
</style>
